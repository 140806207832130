import React from 'react';
import { Swiper } from 'swiper/react';
import {Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper';
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/a11y";
import PropTypes from 'prop-types';

SwiperSlider.propTypes = {
    spaceBetween: PropTypes.number,
    delay: PropTypes.number,
    pagination: PropTypes.bool,
    isVertical: PropTypes.bool,
    navigation: PropTypes.bool,
    slidesPerView: PropTypes.number,
    slidesPerGroup: PropTypes.number,
    loop: PropTypes.bool,
    scrollbar: PropTypes.bool
};

function SwiperSlider(
    {
        children,
        spaceBetween = 50,
        slidesPerView = 1,
        slidesPerGroup = 1,
        delay = 5000,
        scrollbar = true,
        loop = true,
        navigation = true,
        pagination = true,
        isVertical = false,
        className
    }
) {
    return (
        <Swiper
            className={className}
            spaceBetween={spaceBetween}
            slidesPerView={slidesPerView}
            slidesPerGroup={slidesPerGroup}
            modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y]}
            autoplay={{delay: delay, disableOnInteraction: false}}
            scrollbar={scrollbar}
            loop={loop}
            navigation={navigation}
            pagination={pagination}
            direction={isVertical ? 'vertical' : 'horizontal'}
            height={200}
        >
            {children}
        </Swiper>
    );
}

export default SwiperSlider;