import React, {useState, useEffect} from 'react';
import axiosPrivate from "../Axios";
import helpers from "../Constants/helpers";
import SingleCategory from "./SingleCategory";
import {Link, useNavigate} from "react-router-dom";

function Categories({ sectionId }) {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        if (sectionId !== 0 && sectionId !== null && sectionId !== undefined && sectionId !== "") {
            setCategories([]);
            axiosPrivate
                .get(`/ClientSide/Get?objectName=5&start=0&end=10000000&subLevelId=${sectionId}&sort=sort&order=asc`)
                .then((res) => {
                    setCategories(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [sectionId]);

    return (
        <section className="container pt-lg-3 mb-4 mb-sm-5">
            {categories && categories.map((category, index) => (
                <div className={`row mb-2`} key={index}>
                    <div className="col-md-4">
                        <div className="d-flex flex-column overflow-hidden rounded-3" style={{ backgroundColor: "#f6f8fb", height: "92%" }}>
                            <div className="d-flex justify-content-between px-grid-gutter py-grid-gutter">
                                <h6 className="mb-1">{category.name}</h6>
                            </div>
                            <Link to={`/shop?category=${category.id}&level=false`} className="d-none d-md-block mt-1">
                                <img className="d-block w-100" src={helpers.getImageUrl(category.attachment)} alt={category.name} />
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-8 pt-4 pt-md-0">
                        <SingleCategory categoryId={category.id} />
                    </div>
                </div>
            ))}
        </section>
    );
}

export default Categories;