import React from 'react';
import helpers from "../Constants/helpers";
import {Link} from "react-router-dom";
import CornerRibbon from "react-corner-ribbon";

function SingleProduct({ product, setProductId }) {
    return (
        <div>
            {product && <div className="card product-card" style={{ border: "1px solid #cccccc", borderRadius: 2, maxHeight: "450px", maxWidth: "100%" }}>
                {product.shippingPrice === 0 && <CornerRibbon position="top-right" fontColor="#f0f0f0" backgroundColor="#2c7">{window.t('freeShipping')}</CornerRibbon>}
                <Link to={`/product-details/${product.id}`} className="card-img-top d-block overflow-hidden" style={{ borderRadius: 2 }}>
                    <img src={helpers.getImageUrl(product.attachment)} alt={product.name} style={{ maxHeight: "350px", maxWidth: "100%", height: "350px", width: "100%", objectFit: "cover" }} />
                </Link>
                <div className="card-body py-2">
                    <h3 className="product-title fs-sm text-center">
                        <Link to={`/product-details/${product.id}`}>{product.name}</Link>
                    </h3>
                    <div className="d-flex justify-content-center">
                        <div className="product-price">
                            <p className="text-accent">
                                {window.prefix !== "null" && window.prefix}{parseFloat(parseFloat(product.price * parseFloat(window.rate ?? 1)).toFixed(2)).toLocaleString()} {window.suffix !== "null" && window.suffix}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="card-body card-body-hidden">
                    <div className="text-center">
                        <span className="nav-link-style fs-ms" style={{ cursor: "pointer" }} onClick={() => {
                            setProductId(product.id);
                            helpers.openModal();
                        }}>
                            <i className="ci-eye align-middle me-1"></i>
                            Quick view
                        </span>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default SingleProduct;