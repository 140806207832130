import React from "react";
import helpers from "./../Constants/helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination, Scrollbar, A11y } from "swiper";

const FamiliesData = ({ familiesData }) => {
  return (
    <>
      {familiesData && (
        <div className="families-data row my-3 d-flex justify-content-center bg-dark p-4 mb-4 mt-2 mx-1 radius-10">
          <Swiper
            slidesPerView={2}
            modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
            pagination={false}
            spaceBetween={10}
            navigation={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={false}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
            }}
          >
            <center>
              {familiesData &&
                familiesData.map((family) => (
                  <SwiperSlide key={family.id}>
                    <img
                      src={helpers.getImageUrl(family.attachment)}
                      alt={family.name}
                      className="mx-2 my-2"
                      style={{
                        width: "100%",
                        height: "150px",
                        objectFit: "contain",
                      }}
                      onClick={() => {
                        document.getElementById(`family_${family.id}`).click();
                      }}
                    />
                    <p className="text-center text-light">{family.name}</p>
                  </SwiperSlide>
                ))}
            </center>
          </Swiper>
        </div>
      )}
    </>
  );
};

export default FamiliesData;
