import "./App.css";
import Routing from "./Router";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Navbar from "./Components/Navbar";
import SignInModal from "./Components/SignInModal";
import Footer from "./Components/Footer";
import GoToTop from "./Components/GoToTop";
import BottomNavbar from "./Components/BottomNavbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { rtl } from "./Constants/rtl";

function App() {
  const { t, i18n } = useTranslation();
  window.t = t;
  window.lang = i18n;
  const location = useLocation();

  useEffect(() => {
    rtl();
    var navbarCollapseSelector = document.querySelector(".navbar-collapse");
    if (navbarCollapseSelector.classList.contains("show")) {
      navbarCollapseSelector.classList.remove("show");
    }
  }, [location]);
  useEffect(() => {
    rtl();
  }, []);

  return (
    <div className="app" style={{ overflow: "hidden" }}>
      <SignInModal />
      <main className="page-wrapper">
        <Navbar />
        <Routing />
        <Footer />
        <BottomNavbar />
        <GoToTop />
      </main>
      <ToastContainer />
    </div>
  );
}

export default App;
