import React, { useState } from "react";
import LeftSidebar, { FamiliesContext } from "../Components/LeftSidebar";
import SingleProduct from "../Components/SingleProduct";
import PaginationComponent from "../Components/Pagination";
import QuickViewModal from "../Components/QuickViewModal";
import helpers from "../Constants/helpers";
import FamiliesData from "../Components/FamiliesData";

// import FamiliesData from "../Components/FamiliesData";
import { useSearchParams } from "react-router-dom";
import { func } from "prop-types";

function arrayToObject(remove, ...arr) {
  let obj = {};
  arr.forEach(function (valueArr) {
    for (let i = 0; i < valueArr.length; i += 2) {
      if (valueArr[i] == remove) {
        break;
      } else {
        obj[valueArr[i]] = valueArr[i + 1];
      }
    }
  });
  // console.log("JOseph final obj : ", obj);

  return obj;
}

function Shop() {
  const [sortBy, setSortBy] = useState("popularity");
  const [productId, setProductId] = useState(0);
  const [products, setProducts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const pagesCount = Math.ceil(totalCount / perPage);
  const [searchCode, setSearchCode] = useState(null);
  const [searchSeason, setSearchSeason] = useState(null);
  const [searchStock, setSearchStock] = useState(null);
  const [familiesData, setFamiliesData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  //get data from child component (LeftSidebar.jsx)
  const getFamiliesData = (items) => {
    setFamiliesData(items);
  };
  const BottomPageChanged = (newPageNumber) => {
    handleFilterChange(
      "page",
      +newPageNumber
    );

  };
  const handleFilterChange = (key, value, remove = "") => {
    setSearchParams({
      ...arrayToObject(remove, ...searchParams),
      [key]: value,
    });
  };

  return (
    <div>
      <div className="page-title-overlap bg-dark pt-4">
        <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
          <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
            <h1 className="h3 text-light mb-0">{window.t("shop")}</h1>
          </div>
        </div>
      </div>
      <div className="container-fluid pb-5 mb-2 mb-md-4">
        <div className="row">
          <aside className="col-lg-3">
            <LeftSidebar
              setProducts={setProducts}
              setTotalCount={setTotalCount}
              // currentPage={currentPage}
              currentPage={+searchParams.get("page") || 1}
              // sortBy={sortBy}
              sortBy={searchParams.get("sort")}
              searchCode={searchParams.get("searchCode")}
              searchSeason={searchParams.get("season")}
              searchStock={searchParams.get("stock")}
              // searchCode={searchCode}
              // searchSeason={searchSeason}
              // searchStock={searchStock}

              passFamiliesData={getFamiliesData}
            />
          </aside>
          <section className="col-lg-9">
            <div className="row">
              <div className="d-flex flex-wrap justify-content-center justify-content-sm-between align-items-center pt-2 pb-4 pb-sm-5">
                <div className="col-lg-4 d-flex flex-wrap justify-content-center col-6">
                  <div className="d-flex flex-wrap">
                    <div className="d-flex align-items-center flex-nowrap me-3 me-sm-4 pb-3">
                      <label
                        className="text-light opacity-75 text-nowrap fs-sm me-2 d-none d-sm-block"
                        htmlFor="sorting"
                      >
                        {window.t("sortBy")}
                      </label>
                      <select
                        className="form-select"
                        id="sorting"
                        onChange={(e) => {
                          handleFilterChange("sort", e.target.value);
                        }}
                      >
                        <option value={null}>{window.t("popularity")}</option>
                        <option value={`lowToHigh`}>{window.t("LtHp")}</option>
                        <option value={`highToLow`}>{window.t("HtLp")}</option>
                        <option value={`aToZ`}>{window.t("AtZ")}</option>
                        <option value={`zToA`}>{window.t("ZtA")}</option>
                        <option value={`aToZcode`}>
                          {window.t("AtZCode")}
                        </option>
                        <option value={`zToAcode`}>
                          {window.t("ZtACode")}
                        </option>
                        <option value={`freeShipping`}>
                          {window.t("shippingPrice")}
                        </option>
                      </select>
                      <span className="fs-sm text-light opacity-75 text-nowrap ms-2 d-none d-md-block">
                        {window.t("of")} &nbsp; {totalCount} &nbsp;{" "}
                        {window.t("products")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 d-flex flex-wrap justify-content-center col-6">
                  <div className="d-flex flex-wrap">
                    <div className="d-flex align-items-center flex-nowrap me-3 me-sm-4 pb-3">
                      <input
                        type={`text`}
                        className="form-control widget-filter-search"
                        id="code-search"
                        placeholder={window.t("searchCode")}
                        onChange={(e) => {
                          if (e.target.value.length > 3) {
                            // setSearchCode(e.target.value);
                            handleFilterChange("searchCode", e.target.value);
                          }
                        }}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 d-flex flex-wrap justify-content-center col-6">
                  <div className="d-flex flex-wrap">
                    <div className="d-flex align-items-center flex-nowrap me-3 me-sm-4 pb-3">
                      {/* <label className="text-light opacity-75 text-nowrap fs-sm me-2 d-none d-sm-block" htmlFor="searchSeason">Search Season:</label> */}
                      <select
                        className="form-select"
                        id="searchStock"
                        onChange={(e) => {
                          // if (e.target.value === "Stock") {
                          //   // handleFilterChange(
                          //   //   "stock",
                          //   //   e.target.value,
                          //   //   "stock"
                          //   // );
                          // } else {
                          handleFilterChange("stock", e.target.value);
                          // }
                        }}
                      >
                        <option value={null}>{window.t("stock")}</option>
                        <option value={`available`}>
                          {window.t("available")}
                        </option>
                        <option value={`iraqStock`}>
                          {window.t("iraqStock")}
                        </option>
                        <option value={`chinaStock`}>
                          {window.t("chinaStock")}
                        </option>
                        <option value={`iranStock`}>
                          {window.t("iranStock")}
                        </option>
                        <option value={`turkeyStock`}>
                          {window.t("turkeyStock")}
                        </option>
                        <option value={`uaeStock`}>
                          {window.t("uaeStock")}
                        </option>
                        <option value={`europeStock`}>
                          {window.t("europeStock")}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 d-flex flex-wrap justify-content-center col-6">
                  <div className="d-flex flex-wrap">
                    <div className="d-flex align-items-center flex-nowrap me-3 me-sm-4 pb-3">
                      {/* <label className="text-light opacity-75 text-nowrap fs-sm me-2 d-none d-sm-block" htmlFor="searchSeason">Search Season:</label> */}
                      <select
                        className="form-select"
                        id="searchSeason"
                        onChange={(e) => {
                          // if (e.target.value === "Seasons") {
                          //   setSearchSeason(null);
                          // } else {
                          //   setSearchSeason(e.target.value);
                          // }
                          handleFilterChange("season", e.target.value);
                        }}
                      >
                        <option value={null}>{window.t("seasons")}</option>
                        <option value={`spring`}>{window.t("spring")}</option>
                        <option value={`summer`}>{window.t("summer")}</option>
                        <option value={`autumn`}>{window.t("autumn")}</option>
                        <option value={`winter`}>{window.t("winter")}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 d-flex flex-wrap justify-content-center col-6">
                  <div className="d-flex pb-3">
                    <a
                      className="nav-link-style nav-link-light me-3"
                      onClick={() => {
                        if (+searchParams.get("page") !== 1) {
                          handleFilterChange(
                            "page",
                            +searchParams.get("page") - 1
                          );
                        }
                      }}
                    >
                      <i className="ci-arrow-left text-primary"></i>
                    </a>
                    <span className="fs-md text-primary">
                      {+searchParams.get("page")} / {pagesCount}
                    </span>
                    <a
                      className="nav-link-style text-primary nav-link-light ms-3"
                      onClick={() => {
                        if (+searchParams.get("page") !== pagesCount) {
                          handleFilterChange(
                            "page",
                            +searchParams.get("page") + 1
                          );
                        }
                      }}
                    >
                      <i className="ci-arrow-right text-primary"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-n2"></div>

            {/* familiy images */}
            <FamiliesData familiesData={familiesData} />
            {/* end familiy images */}

            {/* <h1 className="my-3">{window.t('products')}</h1> */}
            <div className="row mx-n2">
              {products &&
                products.map((product, index) => (
                  <div className="col-md-3 col-sm-6 px-2 mb-4" key={index}>
                    <SingleProduct
                      product={product}
                      setProductId={setProductId}
                    />
                    <hr className="d-sm-none" />
                  </div>
                ))}
              <QuickViewModal productId={productId} />
            </div>

            <hr className="my-3" />

            <nav
              className="d-flex justify-content-center pt-2"
              aria-label="Page navigation"
            >
              <PaginationComponent
                itemsCount={totalCount}
                itemsPerPage={perPage}
                // currentPage={currentPage}
                currentPage={+searchParams.get("page") || 1}
                setCurrentPage={BottomPageChanged}
                alwaysShown={false}
              />
            </nav>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Shop;
