import React from "react";
import TopBar from "./TopBar";
import TopCategories from "./TopCategories";
import NavbarContent from "./NavbarContent";

function Navbar() {
  return (
    <header className="shadow-sm">
      {/* <TopBar /> */}
      <div className="navbar-sticky bg-light">
        <NavbarContent />
        <TopCategories />
      </div>
    </header>
  );
}

export default Navbar;
