import React, {useState} from 'react';
import Swal from "sweetalert2";
import axiosPrivate from "../Axios";

function Profile() {
    const [firstName, setFirstName] = useState(localStorage.getItem("firstName") ?? null);
    const [middleName, setMiddleName] = useState(localStorage.getItem("middleName") ?? null);
    const [lastName, setLastName] = useState(localStorage.getItem("lastName") ?? null);

    const [oldPassword, setOldPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [confirmNewPassword, setConfirmNewPassword] = useState(null);

    const [image, setImage] = useState(
        localStorage.getItem("image") !== "null"
                ? `${process.env.REACT_APP_BASE_URL}/${localStorage.getItem("image")}`
                : "https://via.placeholder.com/150"
    );
    const [file, setFile] = useState(null);

    function handleFile(e) {
        setFile(e.target.files[0]);
    }

    async function uploadFile() {
        let formData = new FormData();
        formData.append("file", file);
        if (!file) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Please select image',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            await axiosPrivate.post(`/Accounts/UploadProfileImage`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
                .then((response) => {
                    localStorage.setItem("image", response.data);
                    setImage(`${process.env.REACT_APP_BASE_URL}/${response.data}`);
                    window.location.reload();
                })
                .catch((err) => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Error in upload',
                        showConfirmButton: false,
                        timer: 1500
                    });
                });
        }
    }

    async function handleUpdateInfo() {
        if (!firstName || !middleName || !lastName) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Please fill the names',
                showConfirmButton: false,
                timer: 1500
            });
        } else if (
            firstName.length < 3 ||
            middleName.length < 3 ||
            lastName.length < 3
        ) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Names must be more than 2 chars',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            await axiosPrivate.post(`/Accounts/ChangeAccountInfo`, {
                firstName,
                middleName,
                lastName,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
                .then(() => {
                    localStorage.setItem("firstName", firstName);
                    localStorage.setItem("middleName", middleName);
                    localStorage.setItem("lastName", lastName);
                    localStorage.setItem("fullName", firstName + ' ' + middleName + ' ' + lastName);
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Names updated',
                        showConfirmButton: false,
                        timer: 1500
                    });
                })
                .catch((err) => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Error in update',
                        showConfirmButton: false,
                        timer: 1500
                    });
                });
        }
    }

    async function handleUpdatePassword() {
        if (!oldPassword || !newPassword || !confirmNewPassword) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Please write password',
                showConfirmButton: false,
                timer: 1500
            });
        } else if (newPassword !== confirmNewPassword) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'New password and old password not match',
                showConfirmButton: false,
                timer: 1500
            });
        } else {
            await axiosPrivate.post(`/Accounts/ChangePassword`, {
                currentPassword: oldPassword,
                password: newPassword,
                confirmPassword: confirmNewPassword,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
                .catch((err) => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Error in update password',
                        showConfirmButton: false,
                        timer: 1500
                    });
                })
                .finally(() => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Password updated',
                        showConfirmButton: false,
                        timer: 1500
                    });
                });
        }
    }

    return (
        <div className={`m-3`}>
            <section className={`container`}>
                <div className="bg-secondary rounded-3 p-4 mb-4">
                    <div className="d-flex align-items-center">
                        <img className="rounded" src={image} width="90" alt={`${firstName} ${middleName} ${lastName}`} />
                        <div className="ps-3">
                            <input type={`file`} className={`form-control mb-2`} onChange={handleFile}/>
                            <button className="btn btn-light btn-shadow btn-sm mb-2" type="button" onClick={uploadFile}>
                                <i className="ci-loading me-2"></i>Change avatar
                            </button>
                            <div className="p mb-0 fs-ms text-muted">Upload JPG, GIF or PNG image. 300 x 300 required.</div>
                        </div>
                    </div>
                </div>
                <div className="row gx-4 gy-3">
                    <div className="col-sm-4">
                        <label className="form-label" htmlFor="account-fn">First Name</label>
                        <input className="form-control" type="text" id="account-fn" defaultValue={firstName} onChange={e => setFirstName(e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                        <label className="form-label" htmlFor="account-fn">Middle Name</label>
                        <input className="form-control" type="text" id="account-fn" defaultValue={middleName} onChange={e => setMiddleName(e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                        <label className="form-label" htmlFor="account-ln">Last Name</label>
                        <input className="form-control" type="text" id="account-ln" defaultValue={lastName} onChange={e => setLastName(e.target.value)} />
                    </div>
                    <div className="col-12">
                        <hr className="mt-2 mb-3" />
                        <div className="d-flex flex-wrap justify-content-between align-items-center">
                            <button className="btn btn-primary mt-3 mt-sm-0" type="button" onClick={handleUpdateInfo}>Update Names</button>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <label className="form-label" htmlFor="account-pass">Current Password</label>
                        <div className="password-toggle">
                            <input className="form-control" type="password" id="account-pass" onChange={e => setOldPassword(e.target.value)}  />
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <label className="form-label" htmlFor="account-pass">New Password</label>
                        <div className="password-toggle">
                            <input className="form-control" type="password" id="account-pass" onChange={e => setNewPassword(e.target.value)}  />
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <label className="form-label" htmlFor="account-confirm-pass">Confirm Password</label>
                        <div className="password-toggle">
                            <input className="form-control" type="password" id="account-confirm-pass" onChange={e => setConfirmNewPassword(e.target.value)}  />
                        </div>
                    </div>
                    <div className="col-12">
                        <hr className="mt-2 mb-3" />
                        <div className="d-flex flex-wrap justify-content-between align-items-center">
                            <button className="btn btn-primary mt-3 mt-sm-0" type="button" onClick={handleUpdatePassword}>Update password</button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Profile;