import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
// import classNames from "classnames";
import en from "../Assets/Images/Flags/en.svg";
import ar from "../Assets/Images/Flags/ar.svg";
import ku from "../Assets/Images/Flags/ku.svg";

const lngs = {
  en: { nativeName: "English" },
  ku: { nativeName: "كوردی" },
  ar: { nativeName: "العربية" },
};

export default function Language() {
  const { i18n } = useTranslation();
  // var navStyle = classNames("navbar-nav", {
  //   "ms-auto": i18n.resolvedLanguage == "en",
  //   "me-auto": i18n.resolvedLanguage == "ku" || i18n.resolvedLanguage == "ar",
  // });

  const changestyle = () => {
    if (i18n.resolvedLanguage == "ar" || i18n.resolvedLanguage == "ku") {
      // document.querySelector("html").setAttribute("dir", "rtl");
    } else {
      // document.querySelector("html").removeAttribute("dir");
    }
  };
  const changeStyleOnClick = () => {
    localStorage.setItem("language", i18n.resolvedLanguage);
    window.location.reload();
  };
  useEffect(() => {
    changestyle();
  }, []);

  return (
    <>
      <button
        className="btn btn-outline-dark border-dark btn-sm dropdown-toggle px-2"
        type="button"
        data-bs-toggle="dropdown"
      >
        <img
          className="me-2"
          src={
            i18n.resolvedLanguage == "ar"
              ? ar
              : i18n.resolvedLanguage == "ku"
              ? ku
              : en
          }
          width="20"
          alt="English"
        />
        {i18n.resolvedLanguage == "ar"
          ? "العربية"
          : i18n.resolvedLanguage == "ku"
          ? "كوردی"
          : "English"}
      </button>
      <ul className="dropdown-menu mt-4">
        {Object.keys(lngs).map((lng) => (
          <li key={lng}>
            <a
              className="dropdown-item pb-1"
              onClick={() => {
                i18n.changeLanguage(lng);
                changeStyleOnClick();
              }}
            >
              <img
                className="me-2"
                src={lng == "ar" ? ar : lng == "ku" ? ku : en}
                width="20"
                alt="English"
              />
              {lng == "ar" ? "العربية" : lng == "ku" ? "كوردی" : "English"}
            </a>
          </li>
        ))}

        {/* <a href="#" className="nav-link dropdown-toggle">
          {i18n.resolvedLanguage == "ar"
            ? "العربية"
            : i18n.resolvedLanguage == "ku"
            ? "كوردی"
            : "English"}
        </a> */}
      </ul>
    </>
  );
}
