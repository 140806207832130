import React, { useState } from "react";
import navbar from "./../Assets/Images/navbar-logo.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import helpers from "../Constants/helpers";
import axiosPrivate from "../Axios";
import Language from "./Language";
import { useCallback } from "react";
import { useEffect } from "react";

function NavbarContent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchProduct, setSearchProduct] = useState(searchParams.get("q"));
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  const search = () => {
    if (
      searchProduct !== null &&
      searchProduct !== undefined &&
      searchProduct !== ""
    ) {
      navigate(`/shop?q=${searchProduct}`);
    }
  };

  const reset = () => {
    setSearchProduct(null);
    document.getElementById(`product-search-input`).value = null;
    navigate(`/shop`);
  };

  const [currencies, setCurrencies] = useState([]);

  const getCurrency = useCallback(async () => {
    const response = await axiosPrivate.get(
      `/ClientSide/Get?objectName=11&start=0&end=12`
    );
    setCurrencies(response.data);
  }, []);
  useEffect(() => {
    getCurrency().catch();
  }, [getCurrency]);

  const setNewCurrency = (currency) => {
    console.log(currency.attachment, "Attachment");
    window.currencyName = currency.name;
    localStorage.setItem("currencyName", currency.name);
    window.currencyImage = currency.attachment;
    localStorage.setItem("currencyImage", currency.attachment);
    window.rate = currency.rate;
    localStorage.setItem("rate", currency.rate);
    window.prefix = currency.prefix;
    localStorage.setItem("prefix", currency.prefix);
    window.suffix = currency.suffix;
    localStorage.setItem("suffix", currency.suffix);
    window.location.reload();
  };

  return (
    <div className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        <Link
          to={"/"}
          className="navbar-brand d-none d-sm-block me-3 flex-shrink-0"
        >
          <img src={navbar} width="142" alt="ChinaStars" />
        </Link>
        <Link to={"/"} className="navbar-brand d-sm-none me-2">
          <img src={navbar} width="74" alt="ChinaStart" />
        </Link>
        <div className="input-group d-none d-lg-flex flex-nowrap mx-4">
          <i
            className="ci-search position-absolute top-50 start-0 translate-middle-y ms-3 searchInputIcon"
            style={{ cursor: "pointer" }}
            onClick={search}
          ></i>
          <input
            id={`product-search-input`}
            className="form-control rounded-start w-100 searchInput"
            type="search"
            placeholder={window.t("searchForProduct")}
            defaultValue={searchProduct}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                search();
              }
            }}
            onChange={(e) => {
              setSearchProduct(e.target.value);
            }}
          />
          {searchProduct && (
            <i
              className="ci-close position-absolute top-50 end-0 translate-middle-y me-3"
              style={{ cursor: "pointer" }}
              onClick={reset}
            ></i>
          )}
        </div>
        <div className="navbar-toolbar d-flex flex-shrink-0 align-items-center">
          <div className="d-none d-lg-block">
            <div className="btn-group dropdown disable-autohide px-2">
              <Language />
            </div>
            <div className="btn-group dropdown disable-autohide px-2">
              <button
                className="btn text-dark btn-outline-dark border-dark btn-sm dropdown-toggle px-2"
                type="button"
                data-bs-toggle="dropdown"
              >
                <img
                  className="me-2"
                  src={helpers.getImageUrl(window.currencyImage)}
                  width="20"
                  alt="English"
                />
                {window.currencyName}
              </button>
              <ul className="dropdown-menu mt-4">
                {currencies &&
                  currencies.map((currency, index) => (
                    <li
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setNewCurrency(currency);
                      }}
                    >
                      <a className="dropdown-item">
                        <img
                          className="me-2"
                          src={helpers.getImageUrl(currency.attachment)}
                          width="20"
                          alt="Italiano"
                        />
                        {currency.name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-tool navbar-stuck-toggler" href="#">
            <span className="navbar-tool-tooltip">Toggle menu</span>
            <div className="navbar-tool-icon-box">
              <i className="navbar-tool-icon ci-menu"></i>
            </div>
          </a>
          {!isLoggedIn && (
            <a
              className="navbar-tool ms-1 ms-lg-0 me-n1 me-lg-2"
              href="/#"
              data-bs-toggle="modal"
              data-bs-target="#signin-modal"
            >
              <div className="navbar-tool-icon-box">
                <i className="navbar-tool-icon ci-user"></i>
              </div>
              <div className="navbar-tool-text ms-n3">
                <small>Hello, Sign in</small>
                {window.t("myAccount")}
              </div>
            </a>
          )}
          {isLoggedIn && (
            <div className="navbar-tool dropdown ms-3">
              <span className="navbar-tool-icon-box bg-secondary dropdown-toggle">
                <i className="navbar-tool-icon ci-user"></i>
              </span>
              <Link className="navbar-tool-text" to={`/profile`}>
                <small> {window.t("myProfile")}</small>
                {localStorage.getItem("fullName")}
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <div className="widget widget-cart px-3 pt-2 pb-3">
                  <Link className="d-block w-100" to={`/profile`}>
                    <i className="ci-user-circle me-2 fs-base align-middle"></i>
                    {window.t("myProfile")}
                  </Link>
                </div>
                <div className="widget widget-cart px-3 pt-2 pb-3">
                  <Link className="d-block w-100" to={`/orders`}>
                    <i className="ci-list me-2 fs-base align-middle"></i>Orders
                  </Link>
                </div>
                <div className="widget widget-cart px-3 pt-2 pb-3">
                  <Link className="d-block w-100" to={``}>
                    <i className="ci-card me-2 fs-base align-middle"></i>
                    Payments
                  </Link>
                </div>
                <div className="widget widget-cart px-3 pt-2 pb-3">
                  <span
                    className="d-block w-100"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      localStorage.removeItem("token");
                      localStorage.removeItem("firstName");
                      localStorage.removeItem("middleName");
                      localStorage.removeItem("lastName");
                      localStorage.removeItem("fullName");
                      localStorage.removeItem("image");
                      localStorage.removeItem("isLoggedIn");
                      delete axiosPrivate.defaults.headers.common[
                        "Authorization"
                      ];
                      helpers.closeAllModals();
                      navigate("/");
                    }}
                  >
                    <i className="ci-sign-out me-2 fs-base align-middle"></i>
                    {window.t("logout")}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className="navbar-tool ms-3">
            <Link className="navbar-tool-icon-box bg-secondary" to={`/cart`}>
              <span id={`update-cart-number`} className="navbar-tool-label">
                {helpers.getCartItemNumbers()}
              </span>
              <i className="navbar-tool-icon ci-cart"></i>
            </Link>
            <Link className="navbar-tool-text" to={`/cart`}>
              {window.t("myCart")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavbarContent;
