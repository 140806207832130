import axios from "axios";

const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    language:
      localStorage.getItem("language") === "ar"
        ? "Arabic"
        : localStorage.getItem("language") === "ku"
        ? "Kurdish"
        : "English",
  },
});

export default axiosPrivate;
