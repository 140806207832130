export const ku = {
  home: "سەرەكی",
  darkLightMode: "دۆخی تاریک / ڕووناکی",
  //Start Header
  available: "بەردەستە",
  at: "لە",
  //End Header

  //Start Navbar
  searchForProduct: "گەڕان بۆ بەرهەمەکان",
  hello: "سڵاو",
  myAccount: "هەژمارى من",
  departments: "بەشەکان",

  /* Start SignIn */
  signIn: "چوونەژوورەوە",
  username: "ناوی بەکارهێنەر",
  password: "وشەی نهێنی",
  rememberMe: "بیهێنەوە بیرم",
  forgetPassword: "وشەی نهێنیت لەبیرکردووە؟",
  /* End SignIn */

  /* Start SignUp */
  signUp: "خۆت تۆمار بکە",
  fullName: "ناوی تەواو",
  emailAddress: "ناونیشانی ئیمەیڵ",
  confirmPassword: "وشەی نهێنی پشتڕاست بکەرەوە",
  userType: "جۆری بەکارهێنەر",
  generalAdmin: "بەڕێوەبەری گشتی",
  superAdmin: "سوپەر ئەدمین",
  admin: "بەڕێوەبەر",
  default: "ئاسایی",
  /* End SignUp */

  /* Start My Cart */
  myCart: "سەبەتەکەم",
  subtotal: "ژێر کۆی گشتی",
  expandCart: "فراوانکردنی سەبەتە",
  checkout: "پشکنین",
  /* End My Cart */

  //End Navbar

  //Start Navs
  phoneAndTablets: "مۆبایل و تابلێت",
  compAndAcce: "کۆمپیوتەر و پێداویستیەکانی",
  gaming: "یاریکردن",
  cctvAndSecurity: "سی سی تی ڤی و ئاسایش",
  networking: "تۆڕسازی",
  homeApplication: "بەرنامەی ماڵەوە",
  powerSolution: "چارەسەری هێز",
  tools: "ئامرازەکان",
  pos: "وێستگەی فرۆشتن",
  //End Navs

  //Start Shop Cart Page
  yourCart: "سەبەتەکەت",
  products: "بەرهەمەکان",
  contShopping: "بەردەوام بە لە بازاڕکردن",
  quantity: "بڕ",
  additionalComments: "کۆمێنتی زیادە",
  applyPromoCode: "کۆدی پرۆمۆ بەکاربهێنە",
  promoCode: "کۆدی پرۆمۆ",
  proceedToCheckout: " بەردەوام بە بۆ کۆتایی هێنان بە مامەڵە  ",
  remove: "لابردن",
  //End Shop Cart Page

  //Start Main Page
  slidHead1: "جیهانی مۆسیقا لەگەڵ",
  slidBody1: "هێدفۆن",
  slidFooter1: "لە نێوان براندە سەرەکییەکان هەڵبژێرە",
  slidHead2: "گەڕان لە باشترین",
  slidBody2: "کۆکراوەی VR",
  slidFooter2: "لە بازاڕ",
  slidHead3: "سەیری کۆمەڵەی زەبەلاحی",
  slidBody3: "مۆبایلە زیرەکەکان",
  slidFooter3: "و ئیکسسواراتەکانمان بکە.",
  //End Main Page

  //Start Trending Products (Product Component)
  trendingProducts: "بەرهەمە ترێندەکان",
  moreProducts: "بەرهەمی زیاتر",
  addToCart: "زیادکردن بۆ سەبەتە",
  quickView: "بینینی خێرا",
  //End Trending Products (Product Component)

  //Start Product Modal
  reviews: "پێداچوونەوەکان",
  color: "ڕەنگ: ",
  size: "قەبارە: ",
  addToWishlist: "زيادكردن بۆ ليستى ئاره‌زوو",
  compare: "بەراورد",
  productInfo: "زانیاری لەسەر بەرهەم",
  general: "گشتی",
  generalInfo: "زانیاری گشتی",
  model: "مۆدێل: ",
  gender: "ڕەگەز: ",
  osCompatibilty: "گونجاوی سیستەمی کارپێکردن: ",
  physicalSpece: "تایبەتمەندییە فیزیاییەکان",
  shape: "شێوە: ",
  bodyMterial: "مادەی جەستە: ",
  bandMterial: "مادەی باند: ",
  ram: "ڕام : ",
  rom: "بیرگە : ",
  screen: "ڕوونما : ",
  installments: "قیست : ",
  overview: "گشتی ",
  description: "وردەكاری",
  noRAM: "هیچ قەبارەیێكی ڕام بەردەست نیە",
  noROM: "هیچ قەبارەیێكی بیرگە بەردەست نیە",
  noIM: "هیچ شێوازێکی قیست بەردەست نییە",
  noSc: "هیچ قەبارەێكی جیاوازی ڕوونما بەردەست نیە",
  noCo: "هیچ ڕەنگیگ بەردەست نیە",
  availableSizes: "قەبارە بەردەستەکان",
  availableColors: "ڕەنگە بەردەستەکان",
  bottomSizes: "قەبارەی خوارەوە",
  bottomSizes2: "قەبارەی خوارەوە2",
  //End Product Modal

  //Start Prormo Banner
  limitedOffer: "ئۆفەری سنووردار",
  offerHead: "نوێترین و تازەترین",
  offerBody: "نەوەی iPad Pro",
  offerfooter: "بە نرخێکی داشكێندراو. خێراکە!",
  viewOffers: "بینینی ئۆفەرەکان",
  //End Promo Banner

  //Start Product Widget
  bestsellers: "باشترین فرۆشیاره‌كان",
  newSrrivals: "تازە گەیشتووەکان",
  topRated: "هەڵبژێردراو بۆ تۆ",
  //End Product Widget

  //Start SocialMedia Feeds
  smfl: "بینینی نوێترین پێداچوونەوەی ئامێرەکان کە لە فرۆشگاکەمان بەردەستە بۆ کڕین.",
  smfr: "نوێترین ڤیدیۆ لە کەناڵی Techeye",
  moreVideos: "ڤیدیۆی زیاتر",
  //End SocialMedia Feeds

  //Start SocialMedia Info
  readBlog: "بلۆگەکە بخوێنەرەوە",
  readBlogDescr: "نوێترین فرۆشگا و هەواڵی مۆدە و ڕەوتەکان",
  followSocialMedia: "لە ئینستاگرام فۆڵۆو بکەن",
  followSocialMedia2: "لە فەيسبووک ڵايک بکەن",
  //End SocialMedia Info

  //Start Product Carousel
  ymal: "لەوانەیە تۆ ئەمانەشت بە دڵ بێت",
  //End Product Carousel

  //Start Cheaper Together
  cheaperTogether: "هەرزانتر پێکەوە",
  purchaseTogether: "كڕین پێكەوە",
  //End Cheaper Together

  //Start TFHHD
  menu: "مێنۆ",
  wishlist: "ئارەزووەکان",
  filters: "پاڵاوتنەکان",
  //End TFHHD

  //Start Toolbar
  sortBy: " ڕیزکردن بەپێی: ",
  popularity: "ناوبانگ",
  LtHp: "نزم - بەرز ( نرخ )",
  HtLp: "بەرز - نزم ( نرخ )",
  AtZ: "A - Z ناو",
  ZtA: "Z - A ناو",
  AtZCode: "A - Z کۆد",
  ZtACode: "Z - A کۆد",
  shippingPrice: "نرخی گواستنەوە",
  //End Toolbar

  //Start Footer
  shopDept: "بەشەکانی دوکان",
  accAndShippingInfo: "زانیاری ئەکاونت و ناردن",
  stayInformed: "ئاگاداربن",
  aboutUs: "دەربارەی ئێمە",
  downloadApp: "ئەپەکەمان دابەزێنە",
  footerNav1Head: "گەیاندنی خێرا و بێ بەرامبەر",
  footerNav1Descr: "گەیاندن بەخۆڕاییە بۆ هەموو داواکارییەکانی سەرووی ٢٠٠ دۆلار",
  footerNav2Head: "گرەنتی گەڕانەوەی پارە",
  footerNav2Descr: "لە ماوەی ٣٠ ڕۆژدا پارە دەگەڕێنینەوە",
  footerNav3Head: "پشتگیری کڕیار ٢٤/٧",
  footerNav3Descr: "پشتگیری کڕیاران ٢٤/٧ بەردەستە",
  footerNav4Head: "پارەدانی سەرهێڵی پارێزراو",
  footerNav4Descr: "ئێمە خاوەنی SSL / Secure сertificate",
  cr: "© هەموو مافەکان پارێزراوە. دروستکراوە لەلایەن TechEye",
  aboutCompany: "دەربارەی كۆمپانیا",
  ourTeam: "تیمەكەمان",
  careers: "پیشەکان",
  news: "هەواڵەكان",
  downloadOn: "ئەپەكەمان دابەزێنە لە",
  //End Footer

  // Start Shop Filter
  price: "نرخ",
  min: "لە",
  max: "بۆ",
  categories: "پۆلەکان",
  brands: "براندەکان",
  colors: "ڕەنگەكان",
  raf: "هەموو فلتەرەکان لاببە",
  rcf: "فلتەری ڕەنگەکان لاببە",
  //stock
  stock: "کۆگاکان",
  available: "بەردەست",
  iraqStock: "کۆگای عێراق",
  chinaStock: "کۆگای چین",
  iranStock: "کۆگای ئێران",
  turkeyStock: "کۆگای تورکیا",
  uaeStock: "کۆگای ئیمارات",
  europeStock: "کۆگای ئەوروپا",
  //seasons
  seasons: "وەرزەکان",
  spring: "بەهار",
  summer: "هاوین",
  autumn: "پایز",
  winter: "زستان",
  all: "هەموو",
  // End Shop Filter

  // Start Generals
  techeye: "Techeye",
  techeyestore: "Techeye Store",
  shopNow: "ئێستا بازار بکە ",
  shop: "بازاڕكردن ",
  new: "نوێ",
  note: "تێبینی",
  sale: "فرۆشتن",
  viewMore: "زیاتر ببینە",

  kurdish: "کوردی",
  arabic: "عربی",
  english: "English",
  language: "Language",
  usd: "دۆلاری ئەمریكی",
  // End Generals

  contactUs: "پەيوەندى",
  discount: "داشکاندن",
  aboutUsTitle1: `1- له سه ره تای 2003 ده ستیپێكردووه`,
  aboutUsTitle2: `2- ستافی توێژینەوە و پەرەپێدان`,
  aboutUsTitle3: `3- ئەرکی ئێمە`,
  aboutUsTitle4: `4- نیشانە بازرگانییەکان`,
  aboutUsTitle5: `5- مامەڵە و کڕین و ناردن`,
  aboutUsTitle6: `6- ڕێکاری گومرگی`,
  aboutUsTitle7: `7 - بەربڵاوی تۆڕی پەیوەندییەکان`,
  aboutUsTitle8: `8- بازاڕی نێودەوڵەتی`,
  aboutUsTitle9: `9- دیدگای ئێمە`,
  aboutUsTitle10: `تیبينی کۆتایی`,
  //desc
  aboutUsDesc1: `China Stars Co کۆمپانیایەکی بازرگانی گەورەیە کە گرنگی بە کارەکانی دەدات لەسەر تويژینه‌وه‌و په‌ره‌پیدان
    یەكەم كۆمپانیایە لەسەر ئاستی عێراق كە خزمەتگوزاری زنجیرەی دابینكردن لەڕێگەی ئەپڵیكەیشنەوەیەكی گەورە و تەكنەلۆژیای زانیاری پێشكەوتووەوە بەڕێوەدەبات كە هەزاران بەرهەم دەگرێتەوە. یەکێکە لە بناغەکانی گەشەسەندنی خێرا لە وڵاتدا لە دوای دامەزراندنیەوە.`,
  aboutUsDesc2: `ئێمە خاوەنی 19 ساڵ ئەزموونی توێژینەوە و پەرەپێدان لە بازاڕی عێراق و 10 ساڵ ئەزموونی توێژینەوە و پەرەپێدان لە بازاڕی چین. کۆمەڵێک بەڕێوەبەری کوالیتی بەرزمان هەیە، لە بازرگانەکانەوە تا ئەندازیار و میکانیک و پزیشک و پسپۆڕی بازاڕکردن.
    هاوبەشی توێژینەوەی بەرفراوانی لە وڵاتی خۆی و دەرەوەی وڵات دامەزراندووە.
    لە 14 بەڕێوەبەر پێکهاتووە کە توانای تایبەتیان هەیە لە بازاڕی جیهانیدا بە تایبەت بازاڕی چین.
    `,
  aboutUsDesc3: ` ئەرکی ئێمە ئەوەیە کە لەڕێگەی ژێرخانی تەکنەلۆژی و ستراتیژییەکانی بازاڕکردنەوە کارئاسانی لە عێراقدا بکەین.

      ئێمە یارمەتی بازرگانان  دەدەین بە پێدانی ئەو ئامرازانەی کە پێویستن بۆ گەیشتنیان
      به‌ دابینکەرانی چینی و بەرهەمەکانیان بە خێرایی و کارایی.
      ئێمە پردەک لە نێوان کڕیارەکانمان و دابینکەرانی چینی دروست دەکەین کە بەرهەمی کوالێتی باش لە چین دابین دەکەن و گونجاون بۆ بازرگانیکردنیان.
      
      هەروەها ئێمە یارمەتی کۆمپانیا چینییەکان دەدەین بۆ ئەنجامدانی مارکێتینگ بۆ بەرهەمەکانیان و بازرگانیکردن بە براندەکانیان لە بازاڕی وڵاتەکەماندا، بۆ سوودوەرگرتن لە هێزی تەکنەلۆژیای نوێ بۆ پەیوەندیکردن لەگەڵ بەکارهێنەران و کڕیارەکانیان لە ڕێگەی دۆمەینەکەمانەوە و کارکردن بە شێوەیەکی کاراتر.
      بەم پەیوەندییە ئێمە پشتگیری لە گەشەی کارەکانیان دەکەین لە وڵاتەکەماندا .
      
       China Stars-iq.com ملیۆنان بەرهەمتان بۆ دەسته‌به‌رده‌كات لە زیاتر لە 4000 به‌شی جیاوازدا، لەوانە ئەلیکترۆنیاتی بەکاربەر، ئامێر و جلوبەرگ.
      ئێمە پلاتفۆرمێکی وێبمان پەرەپێدا، کە یارمەتی بازرگانان دەدات کڕینەکانیان ئەنجام بدەن بە شێوەیەکی کارا بەدواداچوونیان بۆ بکەن، و دەرفەتی نوێ بدۆزنەوە، لە هەر شوێنێک و لە هەر کاتێکدا.
      
      بۆ زانینی هەموو پێداویستیەکانی کارەکەت ڕوو له‌ ماڵپەڕی China Stars-iq.com بکە.`,
  aboutUsDesc4: `China Starsکۆمپانیایەکی گەورەیە کە توانای بینینی براند و داهێنان و کاریگەری زۆری هەیە. لە ئێستادا هێمایەکی بازرگانی ناسراوە لە عێراقدا.
    China Stars دیزاینی پیشەیی بەکاردەهێنن بۆ براندەکانی و براندی کڕیارەکانیان کە پشتگیری دەکرێت لەلایەن تەکنەلۆژیای بەڕێوەبردنی بەها زیادکراوی کارامەوە کە لەسەر بنەمای پلاتفۆرمی بازرگانی ئەلیکترۆنی دامەزراوە. پلاتفۆرمەکە به‌  یەکخستنی سەرچاوە ناوخۆیی و دەرەکییەکان و یەکخستنی بازرگانی، لۆجستیک، زانیاری، و سەرمایە بە تەواوی دابین دەکات. داهێنانەکە بەردەوام به‌ ئەپلیکەوه بەستراوەتەوە بە خزمەتگوزارییەکانی کارکردنی زنجیرەیی تایبه‌تمان  لە نموونه‌ی  بازرگانیماندایه‌
    `,
  aboutUsDesc5: `ئێمە شارەزاین لە کاڵای گرێبەست و کڕین. هەروەها پرۆسەی لۆجستیکی لە دەرگا بە دەرگا ئەنجام دەدەین.
    ئێمە کاڵاکان لە سەرتاسەری چین کۆدەکەینەوە و بە ڕێکارێکی لۆجستیکی کاریگەری تێچوون و کات پاشەکەوتکردن و سەلامەت دەینێرین.`,
  aboutUsDesc6: `ئێمە باشترین بەندەری وەرگرتن هەڵدەبژێرین (بەندەری ئوم-قەسر لە عێراق، بەندەری عەباس لە ئێران یان بەندەری مێرسین لە تورکیا).
    ئێمە بەندەر و ڕێگاکانی لۆجستی هەڵدەبژێرین بەپێی جۆری کاڵاکان و ستانداردە لۆجستییەکانی گەیاندنی هەر جۆرە بەرهەمێک. رێکاری لۆجستیکی ئێمە بەپێی ستانداردە نێودەوڵەتییەکان و پێداویستییەکانی حکومەتی عێراق ئەنجام دەدرێت.`,
  aboutUsDesc7: `ئێمە بە سەرکەوتوویی پەیوەندی هاوبەشی ستراتیژی لەگەڵ زۆرێک لە براندە بەناوبانگەکانی چینی بۆ هەموو پۆلەکانی بازرگانی دادەمەزرێنین.
    تەنها پەیوەندیمان پێوە بکەن و بزانن ئێمە چەندە پیشەییین لە کارەکەدا. خزمەتگوزاری تیمی پیشەییمان گەرەنتی سەرکەوتنت دەکات لە کارەکەتدا. ئامانجمان دامەزراندنی تۆڕێکی خزمەتگوزاری تەواوە لەڕێگەی بنیاتنانی پەیوەندی درێژخایەن و دۆستانە لەگەڵ کڕیارانی سەرتاسەری عێراق.`,
  aboutUsDesc8: `لە یەک کاتدا لەسەر بنەمای بازاڕی ناوخۆیی دامەزراوە، و هاوتەریب لەگەڵ ئەوەشدا ئێمە چالاکانە پەرە بە پەیوەندی بازاڕی نێودەوڵەتی دەدەین.`,
  aboutUsDesc9: `ئێمە هەمیشە پابەندین بە دروشمی "ڕاستگۆیی و متمانەی کڕیاران لە پێش هەموو شتێکەوەیە، کوالێتی بەرز و کارایی بەرز لە ئەدای کاردا، داهێنان و کارگێڕی لە بەرهەم و خزمەتگوزارییەکانمان و هاوکاری و بردنەوە لەگەڵ کڕیارەکانمان".

    ئێمە بەوپەڕی خۆشحاڵییەوە چاوەڕێی گەورەبوون دەکەین لەگەڵ ئێوە پێکەوە بۆ ئەوەی سوودی هاوبەشمان هەبێت. کۆمپانیاکەمان بە بەردەوامی لە کێبڕکێی بازاڕدا بەرەو پێشەوە دەڕوات بە خزمەتگوزارییە پێشکەوتووەکانمان و بەرهەمە سەلامەت و متمانەپێکراوەکانمان، و پێشکەوتنێکی بەردەوام لە سیستەمی نەرمەکاڵاکانمان بۆ گه‌یشتن به‌ باشترین خزمەتگوزاریی و گه‌وره‌ترین کارامەیی.
    تیبينی کۆتایی`,
  aboutUsDesc10: `China stars.co سیاسەتی کۆمپانیاکە پابەندە بە "هەموو هەوڵێکمان بۆ دابینکردنی پێداویستییەکانی کڕیاران".
    بەڵێن دەدەین کە کوالێتی باشی هەریەکەكی بەرهەم مسۆگەر بکەین.
    ئێمە لە دڵەوە هیوادارین بتوانین پەرە بە کاروبارەکان بدەین لەسەر بنەمای سوودی هاوبەش.
    China stars.co یەکەمه‌ لە دابینکردنی زنجیرەی دابینکردنی له‌ ده‌رگای چین بۆ ده‌رگای عێراق. ئێمە بەهای داهێنان لە هەموو ڕێکار و خزمەتگوزارییەکانماندا تێکەڵ دەکەین. بۆ گەرەنتیکردنی داهاتوویەکی گەش بۆ کارەکەت دەتوانیت چاوتان پێمان بکەوێت. بەخێرهاتنێکی گەرم لە کڕیارە خۆشەویستەکانمان دەکەین.`,
  freeShipping: "گواستنەوەی خۆڕایی",
  pleaseWriteQuantity: "تکایە بڕ بنوسە",
  cantOrderLessThanMinQuantity: "ناتوانێت داوای کەمتر لە بڕی رێپێدراو بکات",
  factorOrderingInfo:
    "دەتوانیت داوای هەر سێتێک بکەیت بەپێی فاکتەرەکانی ریزبەندی بۆ نمونە دەتوانی داوا بکەیت ",
  addedToCart: "زیادکرا بۆ سەبەتە",
  shippingOptions: "هەڵبژاردنەکانی بارکردن",
  courier: "کۆریەر",
  share: "هاوبەشکردن",
  relatedProducts: "بەرهەمە پەیوەندیدارەکان",
  code: "کۆد",
  barcode: "بارکۆد",
  minOrder: "کەمترین داواکاری",
  qty: "بڕ",
  resetFilters: "فلتەرەکان لابدە",
  applyFilters: "فلتەرەکان دابنێ",
  sections: "بەشەکان",
  families: "خێزانەکان",
  minPrice: "کەمترین نرخ",
  maxPrice: "زۆرترین نرخ",
  search: "گەڕان",
  searchCode: "بەکۆد بگەڕێ",
  of: "لە",
  logout: "چونە دەرەوە",
  myProfile: "پرۆفایلەکەم",
  languageChanged: "زمان گۆڕا",
};
