import React, { useState, useEffect } from "react";
import { SwiperSlide } from "swiper/react";
import SwiperSlider from "../Components/SwiperSlider";
import CustomCarousel from "../Components/CustomCarousel";
import TrendingProducts from "../Components/TrendingProducts";
import HomeWidgets from "../Components/HomeWidgets";
import FollowUs from "../Components/FollowUs";
import Departments from "../Components/Departments";
import Sections from "../Components/Sections";
import Categories from "../Components/Categories";
import axiosPrivate from "../Axios";
import helpers from "../Constants/helpers";
import { Link } from "react-router-dom";

function Home() {
  const [department, setDepartment] = useState(null);
  const [section, setSection] = useState(null);
  const [sliders, setSliders] = useState([]);
  const [newArrivals, setNewArrivals] = useState([]);
  const [discounts, setDiscounts] = useState([]);

  useEffect(() => {
    axiosPrivate
      .get(
        "/ClientSide/GetGroup?GroupProductType=FrontPageSlider&start=0&end=1000000&sort=sort&order=asc"
      )
      .then((res) => {
        setSliders(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axiosPrivate
      .get(
        "/ClientSide/GetCurrentGroupProducts?GroupProductType=NewArrivals&start=0&end=1000000"
      )
      .then((res) => {
        setNewArrivals(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axiosPrivate
      .get(
        "/ClientSide/GetCurrentGroupProducts?GroupProductType=Discount&start=0&end=1000000"
      )
      .then((res) => {
        setDiscounts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <section className="bg-secondary py-4">
        <div className="container py-xl-2">
          <div className="row">
            <div className="col-xl-3 hideOnMobile">
              <div className="table-responsive" data-simplebar>
                <div className="d-flex d-xl-block">
                  <h5 className={`text-center`}>{window.t("discount")}</h5>
                  <CustomCarousel
                    itemsPerPage={3}
                    itemsToShow={3}
                    isVertical={true}
                    pagination={true}
                    itemPadding={[0, 50]}
                  >
                    {discounts &&
                      discounts.map((discount, index) => (
                        <Link
                          className="d-flex align-items-center bg-faded-info rounded-3 ps-2 mb-4 me-4 me-xl-0"
                          key={index}
                          to={`/product-details/${discount.id}`}
                          style={{ minWidth: "16rem" }}
                        >
                          <img
                            src={helpers.getImageUrl(discount.attachment)}
                            width="125"
                            alt="Banner"
                          />
                          <div className="px-2">
                            <span>{discount.discount}%</span>
                            <h5 className="mb-2">
                              <span
                                className="fw-light"
                                style={{ fontSize: 11 }}
                              >
                                {discount.name}
                              </span>
                            </h5>
                            <div className="text-info fs-sm">
                              <i className="ci-arrow-right fs-xs ms-1"></i>
                              {window.t("shopNow")}
                            </div>
                          </div>
                        </Link>
                      ))}
                  </CustomCarousel>
                </div>
              </div>
            </div>
            <div className="col-xl-6 pt-xl-4">
              <SwiperSlider className={`h-75`}>
                {sliders &&
                  sliders.map((slider, index) => (
                    <SwiperSlide key={index}>
                      <div style={{ height: "100%" }}>
                        <div className="row" style={{ height: "100%" }}>
                          <div className="col-md-12" style={{ height: "100%" }}>
                            <img
                              className="d-block mx-auto"
                              src={helpers.getImageUrl(slider.attachment)}
                              alt={slider.name}
                              style={{ height: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </SwiperSlider>
            </div>
            <div className="col-xl-3 hideOnMobile">
              <div className="table-responsive" data-simplebar>
                <div className="d-flex d-xl-block">
                  <h5 className={`text-center`}>{window.t("newSrrivals")}</h5>
                  <CustomCarousel
                    itemsPerPage={3}
                    itemsToShow={3}
                    isVertical={true}
                    pagination={true}
                    itemPadding={[0, 50]}
                  >
                    {newArrivals &&
                      newArrivals.map((newArrival, index) => (
                        <Link
                          className="d-flex align-items-center bg-faded-info rounded-3 ps-2 mb-4 me-4 me-xl-0"
                          key={index}
                          to={`/product-details/${newArrival.id}`}
                          style={{ minWidth: "16rem" }}
                        >
                          <img
                            src={helpers.getImageUrl(newArrival.attachment)}
                            width="125"
                            alt="Banner"
                          />
                          <div className="px-2 pLs pR">
                            <h5 className="mb-2">
                              <span
                                className="fw-light"
                                style={{ fontSize: 11 }}
                              >
                                {helpers.getTextByLength(newArrival.name, 40)}
                              </span>
                            </h5>
                            <div className="text-info fs-sm">
                              <i className="ci-arrow-right fs-xs ms-1"></i>
                              {window.t("shopNow")}
                            </div>
                          </div>
                        </Link>
                      ))}
                  </CustomCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Departments department={department} setDepartment={setDepartment} />
      <Sections
        departmentId={department}
        section={section}
        setSection={setSection}
      />
      <Categories sectionId={section} />
      {/* <TrendingProducts /> */}
      {/* <HomeWidgets /> */}
      <FollowUs />
    </div>
  );
}

export default Home;
