import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import AboutUs from "../Pages/AboutUs";
import ContactUs from "../Pages/ContactUs";
import Shop from "../Pages/Shop";
import ProductDetails from "../Pages/ProductDetails";
import Profile from "../Pages/Profile";
import Cart from "../Pages/Cart";
import Orders from "../Pages/Orders";
import NotFound from "../Pages/NotFound";

const Routing = () => {
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/product-details/:id" element={<ProductDetails />} />
      <Route path="/cart" element={<Cart />} />
      {isLoggedIn && <Route path="/profile" element={<Profile />} />}
      {isLoggedIn && <Route path="/orders" element={<Orders />} />}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routing;
