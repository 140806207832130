import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination, Scrollbar, A11y } from "swiper";
import axiosPrivate from "../Axios";
import helpers from "../Constants/helpers";
import { Link } from "react-router-dom";

function Sections({ departmentId, section, setSection }) {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    if (
      departmentId !== 0 &&
      departmentId !== null &&
      departmentId !== undefined &&
      departmentId !== ""
    ) {
      setSections([]);
      axiosPrivate
        .get(
          `/ClientSide/Get?objectName=5&start=0&end=1000000&subLevelId=${departmentId}&sort=sort&order=asc`
        )
        .then((res) => {
          setSections(res.data);
          setSection(res.data[0].id);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [departmentId]);

  function sectionSelected(section) {
    setSection(section);
  }

  return (
    <div className={`container mt-3`}>
      <h4>{window.t('sections')}</h4>
      <Swiper
        slidesPerView={2}
        modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
        pagination={false}
        spaceBetween={20}
        navigation={true}
        autoplay={{
          delay: 2600,
          disableOnInteraction: false,
        }}
        loop={true}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
        }}
      >
        {sections &&
          sections.map((section) => (
            <SwiperSlide key={section.id}>
              <div className="card product-card-alt">
                <div
                  className="product-thumb"
                  onClick={() => sectionSelected(section.id)}
                >
                  <div className="product-card-actions">
                    <a
                      className="btn btn-light btn-icon btn-shadow fs-base mx-2"
                      
                    >
                      <i className="ci-eye"></i>
                    </a>
                  </div>
                  <a className="product-thumb-overlay" >
                    {" "}
                  </a>
                  <img
                    src={helpers.getImageUrl(section.attachment)}
                    alt={section.name}
                    style={{ height: 180 }}
                  />
                </div>
                <div className="card-body">
                  <h3 className="product-title fs-sm mb-2 text-center">
                    <Link to={`/shop?category=${section.id}&level=false`}>
                      {section.name}
                    </Link>
                  </h3>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}

export default Sections;
