import React, { useState, useRef } from "react";
import Carousel from "react-elastic-carousel";

function CustomCarousel({
  children,
  itemsPerPage = 1,
  totalLength = 10,
  autoPlaySpeed = 2000,
  showArrows = false,
  pagination = false,
  isVertical = false,
  itemsToShow = 1,
  itemPadding = [10, 50]
}) {
  const [autoPlay, setAutoPlay] = useState(true);

  const carouselRef = useRef(null);
  const totalPages = Math.ceil(totalLength / itemsPerPage);
  let resetTimeout;
  return (
    <div
      onMouseEnter={() => setAutoPlay(false)}
      onMouseLeave={() => setAutoPlay(true)}
    >
      <Carousel
        verticalMode={isVertical}
        itemsToShow={itemsToShow}
        showArrows={showArrows}
        pagination={pagination}
        enableAutoPlay={autoPlay}
        autoPlaySpeed={autoPlaySpeed}
        ref={carouselRef}
        itemPadding={itemPadding}
        isRTL={true}
        transitionMs={700}
        onNextEnd={({ index }) => {
          clearTimeout(resetTimeout);
          if (index + 1 === totalPages) {
            resetTimeout = setTimeout(() => {
              if (carouselRef) {
                carouselRef.current.goTo(0);
              }
            }, autoPlaySpeed);
          }
        }}
      >
        {children}
      </Carousel>
    </div>
  );
}

export default CustomCarousel;
