import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./Constants/i18n";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./Constants/ScrollToTop";
import { CookiesProvider } from "react-cookie";

const root = ReactDOM.createRoot(document.getElementById("root"));

window.currencyName = localStorage.getItem("currencyName") ?? "USD";
window.currencyImage = localStorage.getItem("currencyImage") ?? null;
window.rate = localStorage.getItem("rate") ?? 1;
window.prefix = localStorage.getItem("prefix") ?? "$";
window.suffix = localStorage.getItem("suffix") ?? null;

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <CookiesProvider>
        <ScrollToTop />
        <App />
      </CookiesProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
