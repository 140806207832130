import React, {useState} from 'react';
import axiosPrivate from "../Axios";
import {useNavigate} from "react-router-dom";
import helpers from "../Constants/helpers";

function SignInModal() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const login = async () => {
        if (username && password) {
            await axiosPrivate.post(`/Accounts/login`, {username, password})
                .then(response => {
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('firstName', response.data.firstName);
                    localStorage.setItem('middleName', response.data.middleName);
                    localStorage.setItem('lastName', response.data.lastName);
                    localStorage.setItem('fullName', response.data.firstName + ' ' + response.data.middleName + ' ' + response.data.lastName);
                    localStorage.setItem('image', response.data.image);
                    localStorage.setItem('isLoggedIn', 'true');
                    axiosPrivate.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
                    helpers.closeAllModals();
                    navigate('/shop');
                })
                .catch(error => {
                    console.log(error, 'Done');
                })
        }
    }

    return (
        <div className="modal fade" id="signin-modal" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header bg-secondary">
                        <ul className="nav nav-tabs card-header-tabs" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link fw-medium active" href="/#signin-tab" data-bs-toggle="tab" role="tab" aria-selected="true">
                                    <i className="ci-unlocked me-2 mt-n1"></i>Sign in
                                </a>
                            </li>
                        </ul>
                        <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body tab-content py-4">
                        <div className="needs-validation tab-pane fade show active" id="signin-tab">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="si-email">Username</label>
                                <input className="form-control" type="text" id="si-email" placeholder="Username" required defaultValue={username} onChange={e => setUsername(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="si-password">Password</label>
                                <div className="password-toggle">
                                    <input className="form-control" type="password" id="si-password" required defaultValue={password} onChange={e => setPassword(e.target.value)} />
                                    <label className="password-toggle-btn" aria-label="Show/hide password">
                                        <input className="password-toggle-check" type="checkbox" />
                                        <span className="password-toggle-indicator"></span>
                                    </label>
                                </div>
                            </div>
                            <button className="btn btn-primary btn-shadow d-block w-100" type="button" onClick={login}>Sign in</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignInModal;