import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import helpers from "../Constants/helpers";
import Swal from "sweetalert2";
import axiosPrivate from "../Axios";
import moment from "moment";

function Cart() {
    const [cartArray, setCartArray] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [note, setNote] = useState('');

    useEffect(() => {
        loadItems();
    }, []);

    const loadItems = () => {
        let array = helpers.getCartItems()
        setCartArray(array);
        setTotalPrice(array.reduce((total, item) => total + (item.price * item.qty), 0).toFixed(2));
    }

    const updateQty = (item, qty) => {
        localStorage.setItem(`item_${item.id}`, JSON.stringify({
            barcode: item.barcode,
            code: item.code,
            id: item.id,
            name: item.name,
            price: item.price,
            qty: parseInt(qty),
            brandId: item.brandId,
            brandName: item.brandName,
            productTypeId: item.productTypeId,
            productTypeName: item.productTypeName,
            attachment: item.attachment,
            thumbnail: item.thumbnail,
            diskSize: item.diskSize,
            ramSize: item.ramSize,
            screenSize: item.screenSize,
            color: item.color,
            customerNote: item.customerNote
        }));
        loadItems();
    }
    const updateCustomerNote = (item, customerNote) => {
        localStorage.setItem(`item_${item.id}`, JSON.stringify({
            barcode: item.barcode,
            code: item.code,
            id: item.id,
            name: item.name,
            price: item.price,
            qty: item.qty,
            brandId: item.brandId,
            brandName: item.brandName,
            productTypeId: item.productTypeId,
            productTypeName: item.productTypeName,
            attachment: item.attachment,
            thumbnail: item.thumbnail,
            diskSize: item.diskSize,
            ramSize: item.ramSize,
            screenSize: item.screenSize,
            color: item.color,
            customerNote: customerNote
        }));
        loadItems();
    }

    const deleteItem = (item) => {
        localStorage.removeItem(`item_${item.id}`)
        loadItems();
    }

    const submitBasket = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to submit the order!!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, order it!'
        }).then((result) => {
            if (result.isConfirmed) {
                let itemArray = helpers.getCartItems().map((item) => {
                    return {
                        productId: item.id,
                        colorId: item.color !== 0 ? item.color.colorId : 1,
                        diskSizeId: item.diskSize !== 0 ? item.diskSize.diskSizeId : 1,
                        ramSizeId: item.ramSize !== 0 ? item.ramSize.ramSizeId : 1,
                        screenSizeId: item.screenSize !== 0 ? item.screenSize.screenSizeId : 1,
                        qty: item.qty,
                        price: item.price,
                    }
                })
                let form = {
                    idCash: false,
                    date: moment(new Date(), 'YYYY-MM-DD HH:mm:ss'),
                    customerNote: note,
                    orders: itemArray
                }
                axiosPrivate.post('/Invoices?invoiceType=SellInvoice', form, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                    .then(() => {
                        Object.keys(localStorage).forEach(function(key) {
                            if (key.startsWith('item_')) {
                                localStorage.removeItem(key);
                            }
                        });
                        setNote('');
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Form Submit',
                            showConfirmButton: false,
                            timer: 1500
                        });
                        loadItems();
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        })
    }

    return (
        <div>
            <div className="page-title-overlap bg-dark pt-4">
                <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
                    {/* <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                                <li className="breadcrumb-item">
                                    <Link className="text-nowrap" to={`/`}>
                                        <i className="ci-home"></i>Home
                                    </Link>
                                </li>
                                <li className="breadcrumb-item text-nowrap">
                                    <Link to={`/shop`}>Shop</Link>
                                </li>
                                <li className="breadcrumb-item text-nowrap active" aria-current="page">Cart</li>
                            </ol>
                        </nav>
                    </div> */}
                    <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
                        <h1 className="h3 text-light mb-0">Your cart</h1>
                    </div>
                </div>
            </div>
            <div className="container pb-5 mb-2 mb-md-4">
                <div className="row">
                    <section className="col-lg-8">
                        <div className="d-flex justify-content-between align-items-center pt-3 pb-4 pb-sm-5 mt-1">
                            <h2 className="h6 text-light mb-0">Products</h2>
                            <Link className="btn btn-outline-primary btn-sm ps-2" to={`/shop`}>
                                {window.t('contShopping')}
                            </Link>
                        </div>
                        {cartArray && cartArray.map((cart, index) => (
                            <div>
                            <div className="d-sm-flex justify-content-between align-items-center my-2 pb-3 border-bottom" key={index}>
                                <div className="d-block d-sm-flex align-items-center text-center text-sm-start">
                                    <Link className="d-inline-block flex-shrink-0 mx-auto me-sm-4" to={`/product-details/${cart.id}`}>
                                        <img src={helpers.getImageUrl(cart.attachment)} width="160" alt={cart.name} />
                                    </Link>
                                    <div className="pt-2">
                                        <h3 className="product-title fs-base mb-2">
                                            <Link to={`/product-details/${cart.id}`}>{cart.name}</Link>
                                        </h3>
                                        {cart.diskSize !== 0 && <div className="fs-sm"><span className="text-muted me-2">Size:</span>{cart.diskSize.name}</div>}
                                        {cart.ramSize !== 0 && <div className="fs-sm"><span className="text-muted me-2">Ram Size:</span>{cart.ramSize.name}</div>}
                                        {cart.screenSize !== 0 && <div className="fs-sm"><span className="text-muted me-2">Size:</span>{cart.screenSize.name}</div>}
                                        {cart.color !== 0 && <div className="fs-sm"><span className="text-muted me-2">Color:</span>{cart.color.name}</div>}
                                        <div className="fs-lg pt-2">Price: <span className={`text-accent`}>${parseFloat(cart.price)}</span></div>
                                        <div className="fs-lg pt-2">Total: <span className={`text-accent`}>${helpers.roundToTwo(parseFloat(parseFloat(cart.price) * parseFloat(cart.qty)))}</span></div>
                                    </div>
                                </div>
                                <div className="pt-2 pt-sm-0 ps-sm-3 mx-auto mx-sm-0 text-center text-sm-start" style={{ maxWidth: "9rem" }}>
                                    <label className="form-label" htmlFor="quantity4">Quantity</label>
                                    <input className="form-control" type="number" id="quantity4" min="1" defaultValue={cart.qty} onChange={(e) => {
                                        updateQty(cart, e.target.value);
                                    }} />
                                    <button className="btn btn-link px-0 text-danger" type="button" onClick={() => {
                                        deleteItem(cart);
                                    }}>
                                        <i className="ci-close-circle me-2"></i><span className="fs-sm">Remove</span>
                                    </button>
                                </div>
                            </div>
                                <div className="pt-2 pt-sm-0 ps-sm-3 mx-auto mx-sm-0 text-center text-sm-start" >
                                    <label className="form-label" htmlFor="note1">Item Note</label>
                                    <input className="form-control" type="text" id="note1"  defaultValue={cart.customerNote} onChange={(e) => {
                                        updateCustomerNote(cart, e.target.value);
                                    }} />
                                </div>
                            </div>
                        ))}
                    </section>
                    <aside className="col-lg-4 pt-4 pt-lg-0 ps-xl-5">
                        <div className="bg-white rounded-3 shadow-lg p-4">
                            <div className="py-2 px-xl-2">
                                <div className="text-center mb-4 pb-3 border-bottom">
                                    <h2 className="h6 mb-3 pb-1">Subtotal</h2>
                                    <h3 className="fw-normal">${totalPrice}</h3>
                                </div>
                                <div className="mb-3 mb-4">
                                    <label className="form-label mb-3" htmlFor="order-comments">
                                        <span className="badge bg-info fs-xs me-2">Invoice Note</span>
                                        <span className="fw-medium">Additional comments</span>
                                    </label>
                                    <textarea className="form-control" rows="6" id="order-comments" onChange={e => setNote(e.target.value)} defaultValue={note}></textarea>
                                </div>
                                <button disabled={helpers.getCartItemNumbers() <= 0} className="btn btn-primary btn-shadow d-block w-100 mt-4" type={`button`} onClick={submitBasket}>
                                    <i className="ci-card fs-lg me-2"></i>Submit Order
                                </button>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    );
}

export default Cart;