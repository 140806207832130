// let done = false;
export function rtl() {
  if (
    localStorage.getItem("language") === "ku" ||
    localStorage.getItem("language") === "ar"
  ) {
    let convertPeToPs = document.querySelectorAll("[class*=pe-]");
    let convertPsToPe = document.querySelectorAll("[class*=ps-]");
    for (const data of convertPeToPs) {
      var result;
      data.classList.forEach((e) => {
        if (e?.startsWith("pe-")) {
          result = e;
        }
      });
      //ms-3
      if (
        `${result}`?.includes("pe-") &&
        !data.classList.contains("editedPEtPS")
      ) {
        data.classList.remove(result);
        data.classList.add(result.replaceAll("e", "s"));
        data.classList.add("editedPEtPS");
      }
    }
    for (const data of convertPsToPe) {
      var result;
      data.classList.forEach((e) => {
        if (e?.startsWith("ps-")) {
          result = e;
        }
      });
      if (
        `${result}`?.includes("ps-") &&
        !data.classList.contains("editedPEtPS")
      ) {
        data.classList.remove(result);
        data.classList.add(result.replaceAll("s", "e"));
        data.classList.add("editedPEtPS");
      }
    }

    let convertMeToMs = document.querySelectorAll("[class*=me-]");
    let convertMsToMe = document.querySelectorAll("[class*=ms-]");
    for (const data of convertMeToMs) {
      var result;

      data.classList.forEach((e) => {
        if (e?.startsWith("me-")) {
          result = e;
        }
      });
      //ms-3
      if (
        `${result}`?.includes("me-") &&
        !data.classList.contains("editedMStME")
      ) {
        data.classList.remove(result);
        data.classList.add(result.replaceAll("e", "s"));
        data.classList.add("editedMStME");
      }
    }
    for (const data of convertMsToMe) {
      var result;

      data.classList.forEach((e) => {
        if (e?.startsWith("ms-")) {
          result = e;
        }
      });
      if (
        `${result}`?.includes("ms-") &&
        !data.classList.contains("editedMStME")
      ) {
        data.classList.remove(result);
        data.classList.add(result.replaceAll("s", "e"));
        data.classList.add("editedMStME");
      }
    }

    let convertStartToEnd = document.querySelectorAll("[class*=start-]");
    let convertEndToStart = document.querySelectorAll("[class*=end-]");

    for (const data of convertStartToEnd) {
      var result;
      data.classList.forEach((e) => {
        if (e?.startsWith("start-")) {
          result = e;
        }
      });
      //ms-3
      if (
        `${result}`?.includes("start-") &&
        !data.classList.contains("editedSE")
      ) {
        data.classList.remove(result);
        data.classList.add(result.replace("start", "end"));
        data.classList.add("editedSE");
      }
    }
    for (const data of convertEndToStart) {
      var result;
      data.classList.forEach((e) => {
        if (e?.startsWith("end-")) {
          result = e;
        }
      });
      if (
        `${result}`?.includes("end-") &&
        !data.classList.contains("editedSE")
      ) {
        data.classList.remove(result);
        data.classList.add(result.replace("end", "start"));
        data.classList.add("editedSE");
      }
    }

    // let roundedStartToEnd = document.querySelectorAll("[class*=rounded-]");
    // for (const roundedStartToEnds of roundedStartToEnd) {
    //   if (roundedStartToEnds.classList.contains("rounded-start")) {
    //     roundedStartToEnds.classList.remove("rounded-start");
    //     roundedStartToEnds.classList.add("rounded-end");
    //   } else if (roundedStartToEnds.classList.contains("rounded-end")) {
    //     roundedStartToEnds.classList.remove("rounded-end");
    //     roundedStartToEnds.classList.add("rounded-start");
    //   }
    // }

    let roundStarts = document.querySelectorAll(".rounded-start");
    let roundEnds = document.querySelectorAll(".rounded-end");
    for (const roundStart of roundStarts) {
      if (
        roundStart.classList.contains("rounded-start") &&
        !roundStart.classList.contains("editedRStRE")
      ) {
        roundStart.classList.remove("rounded-start");
        roundStart.classList.add("rounded-end");
        roundStart.classList.add("editedRStRE");
      }
      for (const roundEnd of roundEnds) {
        if (
          roundEnd.classList.contains("rounded-end") &&
          !roundEnd.classList.contains("editedRStRE")
        ) {
          roundEnd.classList.remove("rounded-end");
          roundEnd.classList.add("rounded-start");
          roundEnd.classList.add("editedRStRE");
        }
      }
    }

    // let textMdStartToTextmdEnds = document.querySelectorAll("[class*=text-md]");
    // for (const textMdStartToTextmdEnd of textMdStartToTextmdEnds) {
    //   if (textMdStartToTextmdEnd.classList.contains("text-md-start")) {
    //     textMdStartToTextmdEnd.classList.remove("text-md-start");
    //     textMdStartToTextmdEnd.classList.add("text-md-end");
    //   } else if (textMdStartToTextmdEnd.classList.contains("text-md-end")) {
    //     textMdStartToTextmdEnd.classList.remove("text-md-end");
    //     textMdStartToTextmdEnd.classList.add("text-md-start");
    //   }
    // }

    let textMdStarts = document.querySelectorAll(".text-md-start");
    let textMdEnds = document.querySelectorAll(".text-md-end");
    for (const textMdStart of textMdStarts) {
      if (
        textMdStart.classList.contains("text-md-start") &&
        !textMdStart.classList.contains("editedMDSE")
      ) {
        textMdStart.classList.remove("text-md-start");
        textMdStart.classList.add("text-md-end");
        textMdStart.classList.add("editedMDSE");
      }
      for (const textMdEnd of textMdEnds) {
        if (
          textMdEnd.classList.contains("text-md-end") &&
          !textMdEnd.classList.contains("editedMDSE")
        ) {
          textMdEnd.classList.remove("text-md-end");
          textMdEnd.classList.add("text-md-start");
          textMdEnd.classList.add("editedMDSE");
        }
      }
    }

    // setTimeout(() => {
    document.querySelector(".page-wrapper").style.direction = "rtl";
    document.body.style.overflowX = "hidden";

    const removeMDonKA = document.querySelectorAll(".remMDonKA");
    for (let i = 0; i < removeMDonKA.length; i++) {
      removeMDonKA[i].classList.remove("text-md-start");
      removeMDonKA[i].classList.add("text-md-end");
    }

    // setTimeout(() => {
      let arrowsRights = document.querySelectorAll(".ci-arrow-right");
      let arrowsLefts = document.querySelectorAll(".ci-arrow-left");
      for (const arrowRight of arrowsRights) {
        if (
          arrowRight.classList.contains("ci-arrow-right") &&
          !arrowRight.classList.contains("editedArrow")
        ) {
          arrowRight.classList.remove("ci-arrow-right");
          arrowRight.classList.add("ci-arrow-left");
          arrowRight.classList.add("editedArrow");
        }
        for (const arrowsLeft of arrowsLefts) {
          if (
            arrowsLeft.classList.contains("ci-arrow-left") &&
            !arrowsLeft.classList.contains("editedArrow")
          ) {
            arrowsLeft.classList.remove("ci-arrow-left");
            arrowsLeft.classList.add("ci-arrow-right");
            arrowsLeft.classList.add("editedArrow");
          }
        }
      }
    // }, 300);
    setTimeout(() => {
      const chechboxRTLCons = document.querySelectorAll(".chechboxRTL");
      for (var i = 0; i < chechboxRTLCons.length; i++) {
        chechboxRTLCons[i].style.position = "absolute";
      }
      const chechboxRtlLableCons =
        document.querySelectorAll(".chechboxRtlLable");
      for (var i = 0; i < chechboxRtlLableCons.length; i++) {
        if (
          chechboxRtlLableCons[i].classList.contains("chechboxRtlLable") &&
          !chechboxRtlLableCons[i].classList.contains("editedCheckBox")
        ) {
          chechboxRtlLableCons[i].style.marginRight = "22px";
          chechboxRtlLableCons[i].classList.add("editedCheckBox");
        }
      }
    }, 1000);

    const mobNN = document.querySelectorAll(".mobNN");
    for (var i = 0; i < mobNN.length; i++) {
      mobNN[i].style.direction = "rtl";
      mobNN[i].style.textAlign = "right";
    }
    const dropdownMenu = document.querySelectorAll(".dropdown-menu");
    for (var i = 0; i < dropdownMenu.length; i++) {
      dropdownMenu[i].style.textAlign = "right";
    }
    const dropdownItem = document.querySelectorAll(".dropdown-item");
    for (var i = 0; i < dropdownItem.length; i++) {
      dropdownItem[i].style.textAlign = "right";
    }

    const carouselSetting = document.querySelectorAll(".carouselSet");
    for (var i = 0; i < carouselSetting.length; i++) {
      carouselSetting[i].style.direction = "rtl";
    }
    const carouselSetLTR = document.querySelectorAll(".carouselSetLTR");
    for (var i = 0; i < carouselSetLTR.length; i++) {
      carouselSetLTR[i].style.direction = "ltr";
    }

    const productCardActions = document.querySelectorAll(
      ".product-card-actions"
    );
    for (var i = 0; i < productCardActions.length; i++) {
      productCardActions[i].style.direction = "ltr";
    }

    var element = document.querySelector(".promoBanner");
    if (element != null && element != undefined) {
      element.style.padding = "50px";
    }

    const allIcons = document.querySelectorAll(".footerBtn");
    for (var i = 0; i < allIcons.length; i++) {
      allIcons[i].style.paddingLeft = "14px";
    }

    let mens = document.querySelectorAll(".me-n1");
    for (const men of mens) {
      men.classList.remove("me-n1");
      men.classList.add("me-n0");
    }

    let metoms = document.querySelectorAll(".me-n3");
    for (const men of metoms) {
      men.classList.remove("me-n3");
      men.classList.add("ms-n3");
    }

    // var rtlNN = document.querySelector(".rtlNN");
    // rtlNN.style.paddingRight = "0";

    let closeFIlterButtonRTL = document.querySelector(".closeFIlterButtonRTL");
    closeFIlterButtonRTL?.classList.remove("ms-auto");
    closeFIlterButtonRTL?.classList.remove("me-auto");

    var searchInput = document.querySelector(".searchInput");
    searchInput.style.paddingRight = "38px";

    var btnClose = document.querySelectorAll(".btn-close");
    for (var i = 0; i < btnClose.length; i++) {
      btnClose[i].style.padding = "0px";
      btnClose[i].style.margin = "0px";
    }

    // var searchInputIcon = document.querySelector(".searchInputIcon");
    // searchInputIcon.classList.remove("start-0");
    // searchInputIcon.classList.add("end-0");

    var emailInput = document.querySelector(".emailInput");
    if (emailInput) {
      emailInput.style.paddingRight = "38px";
    }

    const buttonMail = document.querySelectorAll(".buttonMail");
    for (let i = 0; i < buttonMail.length; i++) {
      buttonMail[i].classList.add("rounded-start");
      buttonMail[i].style.borderRadius = "0 0";
    }

    var topClass = document.querySelector(".topClass");
    if (topClass) {
      topClass.classList.remove("border-start");
      topClass.classList.add("border-end");
      topClass.classList.remove("ps-3");
      topClass.classList.add("pe-3");
    }

    const swipperButtonNexts = document.querySelectorAll(".swiper-button-next");
    for (let i = 0; i < swipperButtonNexts.length; i++) {
      swipperButtonNexts[i].style.transform = "rotate(180deg)";
    }
    const swipperButtonPrev = document.querySelectorAll(".swiper-button-prev");
    for (let i = 0; i < swipperButtonPrev.length; i++) {
      swipperButtonPrev[i].style.transform = "rotate(180deg)";
    }
    // }, 500);
  } else {
    // document.body.removeAttribute("dir");
    document.querySelector(".page-wrapper").style.direction = "ltr";
  }
}
