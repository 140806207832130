import React from "react";

function AboutUs() {
  return (
    <main className="container-fluid px-0">
      <section className="row g-0">
        <div
          className="col-md-6 bg-position-center bg-size-cover bg-secondary"
          style={{
            minHeight: "15rem",
            backgroundImage: "url(assets/img/about/chinaStartsImg2.jpeg)",
          }}
        ></div>
        <div className="col-md-6 px-3 px-md-5 py-5">
          <div className="mx-auto py-lg-5" >
            <h2 className="h3 pb-3">{window.t("aboutUsTitle1")} </h2>
            <p className="fs-sm pb-3 text-muted">{window.t("aboutUsDesc1")}</p>
            <br />
            <h2 className="h3 pb-3">{window.t("aboutUsTitle2")} </h2>
            <p className="fs-sm pb-3 text-muted">{window.t("aboutUsDesc2")}</p>
          </div>
        </div>
      </section>
      <section className="row g-0">
        <div
          className="col-md-6 bg-position-center bg-size-cover bg-secondary order-md-2"
          style={{
            minHeight: "15rem",
            backgroundImage: "url(assets/img/about/chinaStartsImg3.jpeg)",
          }}
        ></div>
        <div className="col-md-6 px-3 px-md-5 py-5 order-md-1">
          <div className="mx-auto py-lg-5" >
            <h2 className="h3 pb-3">{window.t("aboutUsTitle3")}</h2>
            <p className="fs-sm pb-3 text-muted">{window.t("aboutUsDesc3")}</p>
            <h2 className="h3 pb-3">{window.t("aboutUsTitle4")}</h2>
            <p className="fs-sm pb-3 text-muted">{window.t("aboutUsDesc4")}</p>
          </div>
        </div>
      </section>
      <section className="row g-0">
        <div
          className="col-md-6 bg-position-center bg-size-cover bg-secondary"
          style={{
            minHeight: "15rem",
            backgroundImage: "url(assets/img/about/chinaStartsImg4.jpeg)",
          }}
        ></div>
        <div className="col-md-6 px-3 px-md-5 py-5">
          <div className="mx-auto py-lg-5" >
            <h2 className="h3 pb-3">{window.t("aboutUsTitle5")} </h2>
            <p className="fs-sm pb-3 text-muted">{window.t("aboutUsDesc5")}</p>
            <br />
            <h2 className="h3 pb-3">{window.t("aboutUsTitle6")} </h2>
            <p className="fs-sm pb-3 text-muted">{window.t("aboutUsDesc6")}</p>
          </div>
        </div>
      </section>
      <section className="row g-0">
        <div
          className="col-md-6 bg-position-center bg-size-cover bg-secondary order-md-2"
          style={{
            minHeight: "15rem",
            backgroundImage: "url(assets/img/about/chinaStartsImg5.jpeg)",
          }}
        ></div>
        <div className="col-md-6 px-3 px-md-5 py-5 order-md-1">
          <div className="mx-auto py-lg-5" >
            <h2 className="h3 pb-3">{window.t("aboutUsTitle7")}</h2>
            <p className="fs-sm pb-3 text-muted">{window.t("aboutUsDesc7")}</p>
            <h2 className="h3 pb-3">{window.t("aboutUsTitle8")}</h2>
            <p className="fs-sm pb-3 text-muted">{window.t("aboutUsDesc8")}</p>
          </div>
        </div>
      </section>
      <section className="row g-0">
        <div
          className="col-md-6 bg-position-center bg-size-cover bg-secondary"
          style={{
            minHeight: "15rem",
            backgroundImage: "url(assets/img/about/chinaStartsImg1.jpeg)",
          }}
        ></div>
        <div className="col-md-6 px-3 px-md-5 py-5">
          <div className="mx-auto py-lg-5" >
            <h2 className="h3 pb-3">{window.t("aboutUsTitle9")}</h2>
            <p className="fs-sm pb-3 text-muted">{window.t("aboutUsDesc9")}</p>
            <br />
            <h2 className="h3 pb-3">{window.t("aboutUsTitle10")} </h2>
            <p className="fs-sm pb-3 text-muted">{window.t("aboutUsDesc10")}</p>
          </div>
        </div>
      </section>
      {/* <hr />
      <section className="container py-3 py-lg-5 mt-4 mb-3">
        <h2 className="h3 my-2">Our core team</h2>
        <p className="fs-sm text-muted">
          People behind your great shopping experience
        </p>
        <div className="row pt-3">
          <div className="col-lg-4 col-sm-6 mb-grid-gutter">
            <div className="d-flex align-items-center">
              <img
                className="rounded-circle"
                src="assets/img/team/03.jpg"
                width="96"
                alt="Jonathan Doe"
              />
              <div className="ps-3">
                <h6 className="fs-base pt-1 mb-1">Jonathan Doe</h6>
                <p className="fs-ms text-muted mb-0">CEO, Co-founder</p>
                <a
                  className="nav-link-style fs-ms text-nowrap"
                  href="mailto:johndoe@example.com"
                >
                  <i className="ci-mail me-2"></i>johndoe@example.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mb-grid-gutter">
            <div className="d-flex align-items-center">
              <img
                className="rounded-circle"
                src="assets/img/team/04.jpg"
                width="96"
                alt="Barbara Palson"
              />
              <div className="ps-3">
                <h6 className="fs-base pt-1 mb-1">Barbara Palson</h6>
                <p className="fs-ms text-muted mb-0">Chief of Marketing</p>
                <a
                  className="nav-link-style fs-ms text-nowrap"
                  href="mailto:b.palson@example.com"
                >
                  <i className="ci-mail me-2"></i>b.palson@example.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mb-grid-gutter">
            <div className="d-flex align-items-center">
              <img
                className="rounded-circle"
                src="assets/img/team/06.jpg"
                width="96"
                alt="William Smith"
              />
              <div className="ps-3">
                <h6 className="fs-base pt-1 mb-1">William Smith</h6>
                <p className="fs-ms text-muted mb-0">Financial director</p>
                <a
                  className="nav-link-style fs-ms text-nowrap"
                  href="mailto:w.smith@example.com"
                >
                  <i className="ci-mail me-2"></i>w.smith@example.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mb-grid-gutter">
            <div className="d-flex align-items-center">
              <img
                className="rounded-circle"
                src="assets/img/team/02.jpg"
                width="96"
                alt="Amanda Gallaher"
              />
              <div className="ps-3">
                <h6 className="fs-base pt-1 mb-1">Amanda Gallaher</h6>
                <p className="fs-ms text-muted mb-0">Lead UX designer</p>
                <a
                  className="nav-link-style fs-ms text-nowrap"
                  href="mailto:a.gallaher@example.com"
                >
                  <i className="ci-mail me-2"></i>a.gallaher@example.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mb-grid-gutter">
            <div className="d-flex align-items-center">
              <img
                className="rounded-circle"
                src="assets/img/team/01.jpg"
                width="96"
                alt="Benjamin Miller"
              />
              <div className="ps-3">
                <h6 className="fs-base pt-1 mb-1">Benjamin Miller</h6>
                <p className="fs-ms text-muted mb-0">Website development</p>
                <a
                  className="nav-link-style fs-ms text-nowrap"
                  href="mailto:b.miller@example.com"
                >
                  <i className="ci-mail me-2"></i>b.miller@example.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mb-grid-gutter">
            <div className="d-flex align-items-center">
              <img
                className="rounded-circle"
                src="assets/img/team/07.jpg"
                width="96"
                alt="Miguel Rodrigez"
              />
              <div className="ps-3">
                <h6 className="fs-base pt-1 mb-1">Miguel Rodrigez</h6>
                <p className="fs-ms text-muted mb-0">Content manager</p>
                <a
                  className="nav-link-style fs-ms text-nowrap"
                  href="mailto:b.miller@example.com"
                >
                  <i className="ci-mail me-2"></i>m.rodrigez@example.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section className="row g-0">
        <div
          className="col-md-6 bg-position-center bg-size-cover bg-secondary order-md-2"
          style={{
            minHeight: "15rem",
            backgroundImage: "url(assets/img/about/05.jpg)",
          }}
        ></div>
        <div className="col-md-6 px-3 px-md-5 py-5 order-md-1">
          <div className="mx-auto py-lg-5" >
            <h2 className="h3 mb-2">We are hiring new talents</h2>
            <p className="fs-sm text-muted pb-2">
              If you want to be part of our team please submit you CV using the
              form below:
            </p>
            <form className="needs-validation row g-4" method="post" noValidate>
              <div className="col-sm-6">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Your name"
                  required
                />
              </div>
              <div className="col-sm-6">
                <input
                  className="form-control"
                  type="email"
                  placeholder="Your email"
                  required
                />
              </div>
              <div className="col-12">
                <textarea
                  className="form-control"
                  rows="4"
                  placeholder="Message"
                  required
                ></textarea>
              </div>
              <div className="col-12">
                <input className="form-control" type="file" required />
              </div>
              <div className="col-12">
                <button className="btn btn-info btn-shadow" type="submit">
                  Submit your CV
                </button>
              </div>
            </form>
          </div>
        </div>
      </section> */}
    </main>
  );
}

export default AboutUs;
