import React, { useCallback, useEffect, useState } from "react";
import axiosPrivate from "../Axios";
import helpers from "../Constants/helpers";
import Language from "./Language";
import logo from "./../Assets/Images/logoWhite.png";
import { Link } from "react-router-dom";

function Footer() {
  const [currencies, setCurrencies] = useState([]);

  const getCurrency = useCallback(async () => {
    const response = await axiosPrivate.get(
      `/ClientSide/Get?objectName=11&start=0&end=12`
    );
    setCurrencies(response.data);
  }, []); 

  useEffect(() => {
    getCurrency().catch();
  }, [getCurrency]);

  const setNewCurrency = (currency) => {
    console.log(currency.attachment, "Attachment");
    window.currencyName = currency.name;
    localStorage.setItem("currencyName", currency.name);
    window.currencyImage = currency.attachment;
    localStorage.setItem("currencyImage", currency.attachment);
    window.rate = currency.rate;
    localStorage.setItem("rate", currency.rate);
    window.prefix = currency.prefix;
    localStorage.setItem("prefix", currency.prefix);
    window.suffix = currency.suffix;
    localStorage.setItem("suffix", currency.suffix);
    window.location.reload();
  };

  return (
    <footer className="footer bg-dark pt-5">
      <div className="container">
        <div className="row pb-2">
          <div className="col-md-4 col-sm-6">
            <div className="widget widget-links widget-light pb-2 mb-4">
              <h3 className="widget-title text-light">
                {window.t("shopDept")}
              </h3>
              <ul className="widget-list">
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Sneakers &amp; Athletic
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Athletic Apparel
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Sandals
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Jeans
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Shirts &amp; Tops
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Shorts
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    T-Shirts
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Swimwear
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Clogs &amp; Mules
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Bags &amp; Wallets
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Accessories
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Sunglasses &amp; Eyewear
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Watches
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="widget widget-links widget-light pb-2 mb-4">
              <h3 className="widget-title text-light">
                {window.t("accAndShippingInfo")}
              </h3>
              <ul className="widget-list">
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Your account
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Shipping rates &amp; policies
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Refunds &amp; replacements
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Order tracking
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Delivery info
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Taxes &amp; fees
                  </a>
                </li>
              </ul>
            </div>
            <div className="widget widget-links widget-light pb-2 mb-4">
              <h3 className="widget-title text-light">{window.t("aboutUs")}</h3>
              <ul className="widget-list">
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    About company
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Our team
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    Careers
                  </a>
                </li>
                <li className="widget-list-item">
                  <a className="widget-list-link" href="/#">
                    News
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="widget pb-2 mb-4">
              <h3 className="widget-title text-light pb-1">
                {window.t("stayInformed")}
              </h3>
              <form
                className="subscription-form validate"
                action="https://studio.us12.list-manage.com/subscribe/post?u=c7103e2c981361a6639545bd5&amp;amp;id=29ca296126"
                method="post"
                name="mc-embedded-subscribe-form"
                target="_blank"
                noValidate
              >
                <div className="input-group flex-nowrap">
                  <i className="ci-mail ciMail position-absolute top-50 translate-middle-y text-muted fs-base ms-3"></i>
                  <input
                    className="form-control rounded-start emailInput "
                    type="email"
                    name="EMAIL"
                    placeholder="Your email"
                    required
                  />
                  <button
                    className="btn btn-primary buttonMail "
                    type="submit"
                    name="subscribe"
                  >
                    Subscribe*
                  </button>
                </div>
                <div
                  style={{ position: "absolute", left: "-5000px" }}
                  aria-hidden="true"
                >
                  <input
                    className="subscription-form-antispam"
                    type="text"
                    name="b_c7103e2c981361a6639545bd5_29ca296126"
                    tabIndex="-1"
                  />
                </div>
                <div className="form-text text-light opacity-50">
                  *Subscribe to our newsletter to receive early discount offers,
                  updates and new products info.
                </div>
                <div className="subscription-status"></div>
              </form>
            </div>
            <div className="widget pb-2 mb-4">
              <h3 className="widget-title text-light pb-1">
                {window.t("downloadApp")}
              </h3>
              <div className="d-flex flex-wrap">
                <div className="me-2 mb-2">
                  <a className="btn-market btn-apple" href="/#" role="button">
                    <span className="btn-market-subtitle">
                      {window.t("downloadOn")}
                    </span>
                    <span className="btn-market-title">App Store</span>
                  </a>
                </div>
                <div className="mb-2">
                  <a className="btn-market btn-google" href="/#" role="button">
                    <span className="btn-market-subtitle">
                      {window.t("downloadOn")}
                    </span>
                    <span className="btn-market-title">Google Play</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5 bg-darker">
        <div className="container">
          <div className="row pb-3">
            <div className="col-md-3 col-sm-6 mb-4">
              <div className="d-flex">
                <i
                  className="ci-rocket text-primary"
                  style={{ fontSize: "2.25rem" }}
                ></i>
                <div className="ps-3">
                  <h6 className="fs-base text-light mb-1">
                    {window.t("footerNav1Head")}
                  </h6>
                  <p className="mb-0 fs-ms text-light opacity-50">
                    {window.t("footerNav1Descr")}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-4">
              <div className="d-flex">
                <i
                  className="ci-currency-exchange text-primary"
                  style={{ fontSize: "2.25rem" }}
                ></i>
                <div className="ps-3">
                  <h6 className="fs-base text-light mb-1">
                    {window.t("footerNav2Head")}
                  </h6>
                  <p className="mb-0 fs-ms text-light opacity-50">
                    {window.t("footerNav2Descr")}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-4">
              <div className="d-flex">
                <i
                  className="ci-support text-primary"
                  style={{ fontSize: "2.25rem" }}
                ></i>
                <div className="ps-3">
                  <h6 className="fs-base text-light mb-1">
                    {window.t("footerNav3Head")}
                  </h6>
                  <p className="mb-0 fs-ms text-light opacity-50">
                    {window.t("footerNav3Descr")}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-4">
              <div className="d-flex">
                <i
                  className="ci-card text-primary"
                  style={{ fontSize: "2.25rem" }}
                ></i>
                <div className="ps-3">
                  <h6 className="fs-base text-light mb-1">
                    {window.t("footerNav4Head")}
                  </h6>
                  <p className="mb-0 fs-ms text-light opacity-50">
                    {window.t("footerNav4Descr")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className="hr-light mb-5" />
          <div className="row pb-2">
            <div className="col-md-6 text-center text-md-start mb-4">
              <div className="text-nowrap mb-4">
                <a className="d-inline-block align-middle mt-n1 me-3" href="/#">
                  <img
                    className="d-block"
                    src={logo}
                    width="117"
                    alt="Cartzilla"
                  />
                </a>
                <div className="btn-group dropdown disable-autohide px-2">
                  <Language />
                </div>
                <div className="btn-group dropdown disable-autohide px-2">
                  <button
                    className="btn btn-outline-light border-light btn-sm dropdown-toggle px-2"
                    type="button"
                    data-bs-toggle="dropdown"
                  >
                    <img
                      className="me-2"
                      src={helpers.getImageUrl(window.currencyImage)}
                      width="20"
                      alt="English"
                    />
                    {window.currencyName}
                  </button>
                  <ul className="dropdown-menu my-1">
                    {currencies &&
                      currencies.map((currency, index) => (
                        <li
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setNewCurrency(currency);
                          }}
                        >
                          <a className="dropdown-item">
                            <img
                              className="me-2"
                              src={helpers.getImageUrl(currency.attachment)}
                              width="20"
                              alt="Italiano"
                            />
                            {currency.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="widget widget-links widget-light">
                <ul className="widget-list d-flex flex-wrap justify-content-center justify-content-md-start">
                  <li className="widget-list-item me-4">
                    <Link to="/" className="widget-list-link" href="/#">
                      Home
                    </Link>
                  </li>
                  <li className="widget-list-item me-4">
                    <Link to="/shop" className="widget-list-link" href="/#">
                      Shop
                    </Link>
                  </li>
                  <li className="widget-list-item me-4">
                    <Link to="/about-us" className="widget-list-link" href="/#">
                      About Us
                    </Link>
                  </li>
                  <li className="widget-list-item me-4">
                    <Link
                      to="/contact-us"
                      className="widget-list-link"
                      href="/#"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 text-center text-md-end mb-4">
              <div className="mb-3">
                {/* <a
                  className="btn-social bs-light bs-twitter ms-2 mb-2"
                  href="/#"
                >
                  <i className="ci-twitter"></i>
                </a> */}
                <a
                  className="btn-social bs-light bs-facebook ms-2 mb-2"
                  href="https://www.facebook.com/chinainiraq1"
                  _target="_blank"
                >
                  <i className="ci-facebook"></i>
                </a>
                <a
                  className="btn-social bs-light bs-instagram ms-2 mb-2"
                  href="https://instagram.com/china.in.iraq?igshid=YmMyMTA2M2Y="
                  _target="_blank"
                >
                  <i className="ci-instagram"></i>
                </a>
                {/* <a
                  className="btn-social bs-light bs-pinterest ms-2 mb-2"
                  href="/#"
                >
                  <i className="ci-pinterest"></i>
                </a>
                <a
                  className="btn-social bs-light bs-youtube ms-2 mb-2"
                  href="/#"
                >
                  <i className="ci-youtube"></i>
                </a> */}
              </div>
              <img
                className="d-inline-block"
                src="assets/img/cards-alt.png"
                width="187"
                alt="Payment methods"
              />
            </div>
          </div>
          <div className="pb-4 fs-xs text-light opacity-50 text-center text-md-start">
            {window.t("cr")}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
