import React from "react";
import { useLocation } from 'react-router-dom';
import helpers from './../Constants/helpers';
import { Link } from 'react-router-dom';

function BottomNavbar(props) {
  const location = useLocation();
  return (
    <div className="handheld-toolbar">
      <div className="d-table table-layout-fixed w-100">
        { location.pathname === '/shop' ?
        <a
          className="d-table-cell handheld-toolbar-item"
          href="/#"
          data-bs-toggle="offcanvas"
          data-bs-target="#shop-sidebar"
        >
          <span className="handheld-toolbar-icon">
            <i className="ci-filter-alt"></i>
          </span>
          <span className="handheld-toolbar-label">{window.t('filters')}</span>
        </a>
         : "" }
        {/* <a className="d-table-cell handheld-toolbar-item" href="/#">
          <span className="handheld-toolbar-icon">
            <i className="ci-heart"></i>
          </span>
          <span className="handheld-toolbar-label">Wishlist</span>
        </a> */}
        <a
          className="d-table-cell handheld-toolbar-item"
          href="/#"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          onClick={() => window.scrollTo(0, 0)}
        >
          <span className="handheld-toolbar-icon">
            <i className="ci-menu"></i>
          </span>
          <span className="handheld-toolbar-label">{window.t('menu')}</span>
        </a>
        <Link
          to={"/cart"} className="d-table-cell handheld-toolbar-item" href="/#">
          <span className="handheld-toolbar-icon">
            <i className="ci-cart"></i>
            <span className="badge bg-primary rounded-pill ms-1">{helpers.getCartItemNumbers()}</span>
          </span>
          <span className="handheld-toolbar-label">{window.t('myCart')}</span>
        </Link>
      </div>
    </div>
  );
}

export default BottomNavbar;
