import React from 'react';

function GoToTop(props) {
    return (
        <a className="btn-scroll-top" href="/#" data-scroll>
            <span className="btn-scroll-top-tooltip text-muted fs-sm me-2">Top</span>
            <i className="btn-scroll-top-icon ci-arrow-up"></i>
        </a>
    );
}

export default GoToTop;