import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination, Scrollbar, A11y } from "swiper";
import axiosPrivate from "../Axios";
import helpers from "../Constants/helpers";
import { Link } from "react-router-dom";

function Departments({ department, setDepartment }) {
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    axiosPrivate
      .get(
        "/ClientSide/Get?objectName=5&start=0&end=1000000&sort=sort&order=asc"
      )
      .then((res) => {
        setDepartments(res.data);
        setDepartment(res.data[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function departmentSelected(department) {
    setDepartment(department);
  }

  return (
    <div className={`container mt-3`}>
      <h4>{window.t("departments")}</h4>
      <Swiper
        slidesPerView={2}
        modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
        pagination={false}
        spaceBetween={20}
        navigation={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
        }}
      >
        {departments &&
          departments.map((department) => (
            <SwiperSlide key={department.id}>
              <div className="card product-card-alt">
                <div
                  className="product-thumb"
                  onClick={() => departmentSelected(department.id)}
                >
                  <div className="product-card-actions">
                    <a
                      className="btn btn-light btn-icon btn-shadow fs-base mx-2"
                    >
                      <i className="ci-eye"></i>
                    </a>
                  </div>
                  <a className="product-thumb-overlay">
                    {" "}
                  </a>
                  <img
                    src={helpers.getImageUrl(department.attachment)}
                    alt={department.name}
                    style={{ height: 180 }}
                  />
                </div>
                <div className="card-body">
                  <h3 className="product-title fs-sm mb-2 text-center">
                    <Link to={`/shop?category=${department.id}&level=false`}>
                      {department.name}
                    </Link>
                  </h3>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}

export default Departments;
