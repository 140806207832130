import React, {useState, useEffect} from 'react';
import axiosPrivate from "../Axios";
import PropTypes from "prop-types";
import helpers from "../Constants/helpers";
import {Link, useNavigate} from "react-router-dom";

SingleCategory.propTypes = {
    categoryId: PropTypes.number
};

function SingleCategory({ categoryId }) {
    const [families, setFamilies] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (categoryId !== 0 && categoryId !== null && categoryId !== undefined && categoryId !== "") {
            setFamilies([]);
            axiosPrivate
                .get(`/ClientSide/Get?objectName=5&start=0&end=6&subLevelId=${categoryId}&sort=sort&order=asc`)
                .then((res) => {
                    setFamilies(res.data);
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }, [categoryId]);

    return (
            <div className="row mx-n2 single-category">
            {families && families.map((family, index) => (
                <div className="col-lg-4 col-6 px-0 px-sm-2 mb-sm-4" style={{ cursor: "pointer" }} key={index} onClick={() => {
                    navigate(`/shop?category=${family.id}&level=true`);
                }}>
                    <div className="card product-card m-2">
                        <img className={`card-img-top`} src={helpers.getImageUrl(family.attachment)} alt={family.name} />
                        <div className="card-body py-2">
                            <h3 className="product-title fs-sm text-center">
                                <Link to={`/shop?category=${family.id}`}>{family.name}</Link>
                            </h3>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default SingleCategory;